<section id="footer" class="relative bg-footer bg-cover
us:
us-landscape:
md:h-height-77-md
md-landscape:
lg:
lg-landscape:h-height-144.2
xl-landscape:">
  <div class="relative flex
  us:
  us-landscape:
  md:ml-margin-5-md md:pt-padding-4-md
  md-landscape:
  lg:
  lg-landscape:ml-margin-52 lg-landscape:pt-padding-19
  xl-landscape:">
    <div class="relative
    us:
    us-landscape:
    md:w-width-87.5-md
    md-landscape:
    lg:
    lg-landscape:w-width-80
    xl-landscape:">
      <img class="relative
      us:
      us-landscape:
      md:w-width-60-md md:h-height-14-md md:mb-margin-3-md
      md-landscape:
      lg:
      lg-landscape:w-width-67.5 lg-landscape:h-height-12 lg-landscape:mb-margin-8
      xl-landscape:" src="../../../assets/imgs/home/Group 1182.svg" alt="food truck booking">
      <p class="relative text-white font-PoppinsLight font-normal
      us:
      us-landscape:
      md:text-font-14-md md:leading-leading-20-md
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-27
      xl-landscape:"><a class="font-PoppinsBold">Our mission</a> is to make it simple and affordable for individuals
        and communities to book food trucks, transforming the food industry by reducing its costs and complexity.
      </p>
    </div>

    <div class="relative
    us:
    us-landscape:
    md:mt-margin-4-md md:w-auto md:ml-margin-2-md
    md-landscape:
    lg:
    lg-landscape:mt-margin-2.5 lg-landscape:ml-margin-34.5
    xl-landscape:">
      <p class="relative text-white font-PoppinsRegular font-bold uppercase
      us:
      us-landscape:
      md:text-font-16-md md:leading-leading-24-md
      md-landscape:
      lg:
      lg-landscape:text-font-20 lg-landscape:leading-leading-30
      xl-landscape:">Download
        the app</p>
      <div class="relative
      us:
      us-landscape:
      md:w-width-36-md md:h-height-10-md md:mt-margin-2.5-md md:mb-margin-2.5-md
      md-landscape:
      lg:
      lg-landscape:w-width-40 lg-landscape:h-height-12 lg-landscape:mt-margin-4 lg-landscape:mb-margin-6
      xl-landscape:">
        <a href="https://play.google.com/store/apps/details?id=com.guttrucks.client" target="_blank">
          <img class="rounded-md overflow-hidden" src="../../../assets/imgs/home/g4036.jpg" alt="food truck app">
        </a>
      </div>
      <div class="relative
      us:
      us-landscape:
      md:w-width-32-md md:h-height-10-md
      md-landscape:
      lg:
      lg-landscape:w-width-36 lg-landscape:h-height-12
      xl-landscape:">
        <a href="https://apps.apple.com/us/app/guttrucks/1602980633" target="_blank">
          <img class="rounded-lg overflow-hidden" src="../../../assets/imgs/home/footer mejorado.jpg"
            alt="apple food truck app">
        </a>
      </div>
    </div>

    <div class="relative flex flex-col
    us:
    us-landscape:
    md:mt-margin-4-md md:ml-margin-4-md md:w-auto
    md-landscape:
    lg:
    lg-landscape:gap-y-0.5 lg-landscape:ml-margin-25 lg-landscape:mt-margin-2.5
    xl-landscape:">
      <p class="relative text-white font-PoppinsRegular font-bold uppercase
      us:
      us-landscape:
      md:mb-margin-2.5-md md:text-font-16-md md:leading-leading-24-md
      md-landscape:
      lg:
      lg-landscape:mb-margin-2 lg-landscape:text-font-20 lg-landscape:leading-leading-30
      xl-landscape:">Our
        Company
      </p>
      <a (click)="toHome()" routerLinkActive="active" class="relative text-white capitalize font-PoppinsLight font-normal cursor-pointer uid.
      us:
      us-landscape:
      md:text-font-14-md md:leading-leading-20-md
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-27
      xl-landscape:">Home</a>
      <a (click)="toServices()" routerLinkActive="active" class="relative text-white capitalize font-PoppinsLight font-normal cursor-pointer
      us:
      us-landscape:
      md:text-font-14-md md:leading-leading-20-md
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-27
      xl-landscape:">Services</a>
      <a (click)="toAbout()" routerLinkActive="active" class="relative text-white capitalize font-PoppinsLight font-normal cursor-pointer
      us:
      us-landscape:
      md:text-font-14-md md:leading-leading-20-md
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-27
      xl-landscape:">About Us</a>
      <a class="relative text-white capitalize font-PoppinsLight font-normal
      us:
      us-landscape:
      md:text-font-14-md md:leading-leading-20-md
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-27
      xl-landscape:" href="">Blog</a>
      <a class="relative text-white capitalize font-PoppinsLight font-normal
      us:
      us-landscape:
      md:text-font-14-md md:leading-leading-20-md
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-27
      xl-landscape:" href="#questions">FAQ</a>
      <a (click)="toContact()" routerLinkActive="active" class="relative text-white capitalize font-PoppinsLight font-normal cursor-pointer
      us:
      us-landscape:
      md:text-font-14-md md:leading-leading-20-md
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-27
      xl-landscape:">Contact</a>
      <a class="relative text-white capitalize font-PoppinsLight font-normal
      us:
      us-landscape:
      md:text-font-14-md md:leading-leading-20-md
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-27
      xl-landscape:" href="">Privacy Policies</a>
      <a class="relative text-white capitalize font-PoppinsLight font-normal
      us:
      us-landscape:
      md:text-font-14-md md:leading-leading-20-md
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-27
      xl-landscape:" href="">Terms and Conditions</a>
    </div>

    <div class="relative
    us:
    us-landscape:
    md:ml-margin-4-md
    md-landscape:
    lg:
    lg-landscape:ml-margin-17
    xl-landscape:">
      <p class="relative text-white font-PoppinsRegular font-bold uppercase
        us:
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md md:mb-margin-1-md
        md-landscape:
        lg:
        lg-landscape:text-font-20 lg-landscape:leading-leading-30 lg-landscape:mb-margin-6.5
        xl-landscape:">
        Newsletter
      </p>
      <p class="relative text-white font-PoppinsLight font-normal
      us:
      us-landscape:
      md:text-font-14-md md:leading-leading-20-md md:w-width-72-md md:ml-margin-4-md md:mb-margin-1-md
      md-landscape:
      lg:
      lg-landscape:text-font-14 lg-landscape:leading-leading-16 lg-landscape:mb-1.5 lg-landscape:ml-margin-11 lg-landscape:w-width-72
      xl-landscape:">
        Stay updated
        with our new features and changes on our app. </p>
      <p class="relative text-white font-PoppinsRegular font-bold uppercase
        us:
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md md:mb-margin-1-md
        md-landscape:
        lg:
        lg-landscape:text-font-16 lg-landscape:leading-leading-24 lg-landscape:mb-margin-2.5
        xl-landscape:">
        For users
      </p>

      <div class="relative">
        <input type="text" class="relative bg-white border-none text-gray-B1B1B1
          us:
          us-landscape:
          md:h-height-8-md md:w-width-60-md rounded-2xl md:text-font-12-md md:leading-leading-16-md md:ml-margin-4-md md:mb-margin-1-md
          md-landscape:
          lg:
          lg-landscape:h-height-10 lg-landscape:w-width-72 lg-landscape:mb-margin-7 lg-landscape:rounded-3xl lg-landscape:text-font-12 lg-landscape:leading-leading-16 lg-landscape:ml-margin-11
          xl-landscape:" placeholder="users@guttrucks.com">
        <button class=" absolute bg-red-primary text-white font-PoppinsLight font-normal
          us:
          us-landscape:
          md:text-font-12-md md:leading-leading-16-md md:rounded-2xl md:right-13-md md:top-0.5-md md:w-width-12-md md:h-height-7-md
          md-landscape:
          lg:
          lg-landscape:text-font-12 lg-landscape:leading-leading-16 lg-landscape:right-23 lg-landscape:top-0.5 lg-landscape:rounded-3xl lg-landscape:h-height-9 lg-landscape:w-width-12.7
          xl-landscape:">Send</button>
      </div>

      <p class="relative text-white font-PoppinsRegular font-bold uppercase
        us:
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md md:ml-margin-4-md md:mb-margin-1-md
        md-landscape:
        lg:
        lg-landscape:text-font-20 lg-landscape:leading-leading-30 lg-landscape:mb-margin-2.5 lg-landscape:ml-margin-11
        xl-landscape:">
        FOR FOODTRUCK OWNERS</p>

      <div class="relative">
        <input type="text" class="relative bg-white border-none text-gray-B1B1B1
          us:
          us-landscape:
          md:h-height-8-md md:w-width-60-md rounded-2xl md:text-font-12-md md:leading-leading-16-md md:ml-margin-4-md md:mb-margin-1-md
          md-landscape:
          lg:
          lg-landscape:h-height-10 lg-landscape:w-width-72 lg-landscape:mb-margin-7 lg-landscape:rounded-3xl lg-landscape:text-font-12 lg-landscape:leading-leading-16 lg-landscape:ml-margin-11
          xl-landscape:" placeholder="owner@guttrucks.com">
        <button class=" absolute bg-red-primary text-white font-PoppinsLight font-normal
          us:
          us-landscape:
          md:text-font-12-md md:leading-leading-16-md md:rounded-2xl md:right-13-md md:top-0.5-md md:w-width-12-md md:h-height-7-md
          md-landscape:
          lg:
          lg-landscape:text-font-12 lg-landscape:leading-leading-16 lg-landscape:right-23 lg-landscape:top-0.5 lg-landscape:rounded-3xl lg-landscape:h-height-9 lg-landscape:w-width-12.7
          xl-landscape:">Send</button>
      </div>

      <p class="relative text-white font-PoppinsRegular font-bold uppercase
      us:
      us-landscape:
      md:text-font-16-md md:leading-leading-24-md md:ml-margin-4-md md:mb-margin-1-md
      md-landscape:
      lg:
      lg-landscape:mb-margin-6 lg-landscape:ml-margin-11 lg-landscape:text-font-20 lg-landscape:leading-leading-30
      xl-landscape:">
        Find Us</p>
      <div class="relative flex
      us:
      us-landscape:
      md:gap-x-gap-3-md md:ml-margin-4-md md:mb-margin-1-md
      md-landscape:
      lg:
      lg-landscape:gap-x-gap-12 lg-landscape:ml-margin-11 lg-landscape:mb-margin-6
      xl-landscape:">
        <a href="https://www.facebook.com/GutTrucks/" target="_blank">
          <img class="relative
          us:
        us-landscape:
        md:h-height-6-md md:w-width-6-md
        md-landscape:
        lg:
        lg-landscape:h-height-9 lg-landscape:w-width-9" src="../../../assets/imgs/home/facebookW.svg"
            alt="facebook food truck">
        </a>
        <a href="https://twitter.com/guttrucksapp" target="_blank">
          <img class="relative
          us:
        us-landscape:
        md:h-height-6-md md:w-width-6-md
        md-landscape:
        lg:
        lg-landscape:h-height-9 lg-landscape:w-width-9" src="../../../assets/imgs/home/twitterW.svg"
            alt="twitter food truck">
        </a>
        <img class="relative
        us:
      us-landscape:
      md:h-height-6-md md:w-width-6-md
      md-landscape:
      lg:
      lg-landscape:h-height-9 lg-landscape:w-width-9" src="../../../assets/imgs/home/send lightW.svg"
          alt="food truck request">
        <img class="relative
        us:
      us-landscape:
      md:h-height-6-md md:w-width-6-md
      md-landscape:
      lg:
      lg-landscape:h-height-9 lg-landscape:w-width-9" src="../../../assets/imgs/home/linkedinW.svg"
          alt="linkedin food truck">
        <a href="https://www.instagram.com/guttrucks/" target="_blank">
          <img class="relative
          us:
        us-landscape:
        md:h-height-6-md md:w-width-6-md
        md-landscape:
        lg:
        lg-landscape:h-height-9 lg-landscape:w-width-9" src="../../../assets/imgs/home/linkedin (1)W.svg"
            alt="instagram food truck">
        </a>
      </div>
      <p class="relative text-white font-PoppinsLight font-normal
      us:
      us-landscape:
      md:md:text-font-12-md md:leading-leading-16-md
      md-landscape:
      lg:
      lg-landscape:text-font-14 lg-landscape:leading-leading-21 lg-landscape:ml-margin-11
      xl-landscape:">Guttrucks ©
        2023</p>
    </div>
  </div>

</section>