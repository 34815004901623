<div class="relative w-auto h-auto bg-white
us:
us-landscape:
md:p-padding-4-md
md-landscape:
lg:
lg-landscape:p-1vw
xl-landscape:">
    <div class="relative
    us:
    us-landscape:
    md:w-60vw md:h-46vw
    md-landscape:
    lg:
    lg-landscape:w-40vw lg-landscape:h-32vw
    xl-landscape:" id="modal-map"></div>
    <button (click)="close()" class="relative w-full bg-red-primary text-white font-PoppinsBold border-none outline-none ring-0
    us:
    us-landscape:
    md:h-height-10-md md:text-font-16-md md:mt-margin-4-md md:rounded-md
    md-landscape:
    lg:
    lg-landscape:h-2.6vw lg-landscape:text-font-18 lg-landscape:mt-1.6vw lg-landscape:rounded-lg
    xl-landscape:">Close</button>
</div>
