<section class="menu fixed top-0 left-0 w-full pl-12 pr-12 pt-8 pb-8 flex justify-between z-50
us:hidden
us-landscape:
md:h-20
md-landscape:
lg:inline-flex
lg-landscape:h-20
xl-landscape:h-full-hd-screen-menu-height">
  <div></div>
  <div (click)="openMenu()" class="relative bg-white
    us:
    us-landscape:
    md:block md:h-4 md:w-6
    md-landscape:
    lg:
    lg-landscape:hidden
    xl-landscape:">

    <div class="relative w-full h-3px bg-red-primary rounded-ultra-sm"></div>
    <div class="relative w-full h-3px mt-1 mb-1 bg-red-primary rounded-full"></div>
    <div class="relative w-full h-3px bg-red-primary rounded-full"></div>

  </div>
  <div class="relative flex justify-start items-center h-auto
    us:
    us-landscape:
    md:hidden
    md-landscape:
    lg:
    lg-landscape:inline-flex
    xl-landscape:w-auto">

    <a class=" relative w-auto uppercase font-PoppinsMedium
        us:
        us-landscape:
        md:
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:text-base xl-landscape:mr-5 xl-landscape:ml-5" [routerLink]="[ '/']"
      routerLinkActive="text-red-primary font-PoppinsBold" [routerLinkActiveOptions]="{exact:true}">home</a>
    <a class="relative w-auto font-PoppinsMedium uppercase
        us:
        us-landscape:
        md:
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:text-base xl-landscape:mr-5 xl-landscape:ml-5" [routerLink]="[ '/search']"
      routerLinkActive="text-red-primary font-PoppinsBold" [routerLinkActiveOptions]="{exact:true}">search</a>
    <a class="relative w-auto font-PoppinsMedium uppercase
        us:
        us-landscape:
        md:
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:text-base xl-landscape:mr-5 xl-landscape:ml-5" href="#download"
      routerLinkActive="text-red-primary font-PoppinsBold" [routerLinkActiveOptions]="{exact:true}">download
      app</a>
    <a class="relative w-auto font-PoppinsMedium uppercase
        us:
        us-landscape:
        md:
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:text-base xl-landscape:mr-5 xl-landscape:ml-5" href="#about"
      routerLinkActive="text-red-primary font-PoppinsBold" [routerLinkActiveOptions]="{exact:true}">about us</a>
    <a class="relative w-auto font-PoppinsMedium uppercase
        us:
        us-landscape:
        md:
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:text-base xl-landscape:mr-5 xl-landscape:ml-5" href="#questions"
      routerLinkActive="text-red-primary font-PoppinsBold" [routerLinkActiveOptions]="{exact:true}">faq</a>
    <a class="relative w-auto font-PoppinsMedium uppercase
        us:
        us-landscape:
        md:
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:text-base xl-landscape:mr-5 xl-landscape:ml-5" href="#contactUs"
      routerLinkActive="text-red-primary font-PoppinsBold" [routerLinkActiveOptions]="{exact:true}">contact</a>
    <button *ngIf="isOwner" [routerLink]="['/owners']" class="reative border border-red-primary bg-red-primary text-white flex items-center justify-center uppercase
        us:
        us-landscape:
        md:
        md-landscape:
        lg:
        lg-landscape:w-auto lg-landscape:h-9 lg-landscape:rounded-md lg-landscape:text-sm lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:pl-3 lg-landscape:pr-3
        xl-landscape:text-base xl-landscape:mr-5 xl-landscape:ml-5
        ">
      owners?
    </button>
    <button *ngIf="!isLogged" [routerLink]="[ '/auth/login' ]" [routerLinkActiveOptions]="{exact:true}" class="reative border border-black flex items-center justify-center
        us:
        us-landscape:
        md:
        md-landscape:
        lg:
        lg-landscape:w-9 lg-landscape:h-9 lg-landscape:rounded-md lg-landscape:ml-4 lg-landscape:p-1
        xl-landscape:
        " routerLinkActive="text-white bg-red-primary border-red-primary border-none">
      <img routerLinkActive="fill-white stroke-white" class="relative w-4 h-auto
            us:
            us-landscape:
            md:
            md-landscape:
            lg:
            lg-landscape:
            xl-landscape:" src="../../../assets/icons/menu/user-icon.svg" alt="food truck user">
    </button>
    <button routerLink="/users/profile" *ngIf="isLogged" class="reative border border-red-primary flex items-center justify-center
        us:
        us-landscape:
        md:
        md-landscape:
        lg:
        lg-landscape:w-9 lg-landscape:h-9 lg-landscape:rounded-md lg-landscape:ml-4 lg-landscape:p-1
        xl-landscape:
        ">
      <img routerLinkActive="fill-white stroke-white" class="relative w-full h-full object-contain rounded-4
            us:
            us-landscape:
            md:
            md-landscape:
            lg:
            lg-landscape:
            xl-landscape:" [src]="pict" alt="">
    </button>
  </div>
</section>

<section class="menu fixed top-0 left-0 w-full pl-12 pr-12 pt-8 pb-8 flex justify-end items-center z-50
us:h-20 us:px-padding-3.8-md us:py-padding-7.5-md
us-landscape:
md:h-20 md:px-padding-3.8-md md:py-padding-7.5-md
md-landscape:
lg:hidden
lg-landscape:
xl-landscape:">
  <div class="relative flex justify-between">
    <div class="relative
  us:h-10 us:w-width-57.5-md
  us-landscape:
  md:h-10 md:w-width-57.5-md
  md-landscape:
  lg:hidden
  lg-landscape:
  xl-landscape:">
    </div>
    <div class="relative flex">
      <button class="reative border border-red-primary bg-red-primary text-white flex items-center justify-center uppercase
      us:w-auto us:h-10 us:rounded-lg us:text-base us:pl-4 us:pr-4 us:pt-2 us:pb-2
      us-landscape:
      md:w-auto md:h-10 md:rounded-lg md:text-base md:pl-4 md:pr-4 md:pt-2 md:pb-2
      md-landscape:
      lg:
      lg-landscape:
      xl-landscape:
        ">
        owners?
      </button>
      <button (click)="openMenu()" class="relative border border-black rounded-lg ml-3.5">
        <span class="icon-menu-round-24px-2
        us:text-2xl us:h-10 us:px-2
        us-landscape:
        md:text-2xl md:h-10 md:px-2
        md-landscape:
        lg:
        lg-landscape:
        xl-landscape:">
        </span>
      </button>

    </div>

  </div>

</section>

<nav class="relative">
  <div class="fixed w-screen h-screen left-0 top-0 bg-black transition-opacity ease-in-out duration-500"
    (click)="closeMenu()" [ngClass]="showmenu == true ? 'opacity-40 z-50' : 'opacity-0 z-0'">
  </div>

  <div class="fixed top-0 z-50 h-screen bg-white transition-all ease-in-out duration-700 flex flex-col justify-start gap-y-2 shadow-inner
    us:pt-4 us:pb-4 us:pl-4 us:pr-4 us:w-44 us:inline-flex
    us-landscape:
    md:pt-padding-5-md md:pb-5 md:pl-padding-4-md md:pr-padding-4-md md:w-width-64-md md:inline-flex
    md-landscape:
    lg:
    lg-landscape:hidden" [ngClass]="showmenu == true ? '-left-0' : '-left-72'">
    <p (click)="closeMenu()" class=" relative w-auto capitalize font-PoppinsMedium
        us:text-base
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:" routerLink="/" routerLinkActive="text-red-primary font-PoppinsBold"
      [routerLinkActiveOptions]="{exact:true}">home</p>
    <div class="relative w-full h-1px bg-gray-slider-body-back-color"></div>
    <a (click)="closeMenu()" class="relative w-auto font-PoppinsMedium capitalize
        us:text-base
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:" [routerLink]="[ '/search/null']" routerLinkActive="text-red-primary font-PoppinsBold"
      [routerLinkActiveOptions]="{exact:true}">search</a>
    <div class="relative w-full h-1px bg-gray-slider-body-back-color"></div>
    <a (click)="closeMenu()" class="relative w-auto font-PoppinsMedium capitalize
        us:text-base
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:" href="#download" routerLinkActive="text-red-primary font-PoppinsBold"
      [routerLinkActiveOptions]="{exact:true}">download
      app</a>
    <div class="relative w-full h-1px bg-gray-slider-body-back-color"></div>
    <a (click)="closeMenu()" class="relative w-auto font-PoppinsMedium capitalize
        us:text-base
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:" href="#about" routerLinkActive="text-red-primary font-PoppinsBold"
      [routerLinkActiveOptions]="{exact:true}">about us</a>
    <div class="relative w-full h-1px bg-gray-slider-body-back-color"></div>
    <a (click)="closeMenu()" class="relative w-auto font-PoppinsMedium capitalize
        us:text-base
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:" href="#questions" routerLinkActive="text-red-primary font-PoppinsBold"
      [routerLinkActiveOptions]="{exact:true}">faq</a>
    <div class="relative w-full h-1px bg-gray-slider-body-back-color"></div>
    <a (click)="closeMenu()" class="relative w-auto font-PoppinsMedium capitalize
        us:text-base
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:" href="#contact" routerLinkActive="text-red-primary font-PoppinsBold"
      [routerLinkActiveOptions]="{exact:true}">contact</a>
    <div class="relative w-full h-1px bg-gray-slider-body-back-color"></div>
    <a (click)="closeMenu()" class="relative w-auto font-PoppinsMedium capitalize
        us:text-base
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:" [routerLink]="['/owners']" routerLinkActive="text-red-primary font-PoppinsBold"
      [routerLinkActiveOptions]="{exact:true}">owners</a>
    <div class="relative w-full h-1px bg-gray-slider-body-back-color"></div>
    <a (click)="closeMenu()" class="relative w-auto font-PoppinsMedium capitalize
        us:text-base
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md
        md-landscape:
        lg:
        lg-landscape:ml-4 lg-landscape:mr-4 lg-landscape:text-sm
        xl-landscape:" [routerLink]="[ '/auth/login' ]" routerLinkActive="text-red-primary font-PoppinsBold"
      [routerLinkActiveOptions]="{exact:true}">Log in</a>
  </div>
</nav>