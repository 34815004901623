import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  showmenu = false;
  isOwner: boolean = true;
  isLogged: boolean = false;
  pict: any = false;

  constructor(private notification_service: NotificationsService) { }

  ngOnInit(): void {
    if (localStorage.getItem('email')) {
      this.isLogged = true;
      let photo = localStorage.getItem('photo');
      photo !== 'null' ? this.pict = photo : this.pict = '../../../assets/icons/imago.jpg';
    }
    this.notification_service.getUserStatus().subscribe({
      next: (data) => {
        let photo = localStorage.getItem('photo');
        photo !== 'null' ? this.pict = photo : this.pict = '../../../assets/icons/imago.jpg';
        data == true ? this.isLogged = true : this.isLogged = false;
      },
      error: () => {

      }
    })
  }

  openMenu() {
    this.showmenu = true;
  }

  closeMenu() {
    this.showmenu = false;
  }

  toHome() {
    window.scrollTo(0, 0)
  }

}
