import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlgoliaFireService } from '../../services/algolia.service';

@Component({
  selector: 'app-all-grid',
  templateUrl: './all-grid.component.html',
  styleUrls: ['./all-grid.component.scss']
})
export class AllGridComponent implements OnInit {
  distances: number[] = [25, 50, 75, 100, 125, 150, 175, 200, 300, 1000];
  query: string = '';
  hits: any = [];
  bigGridHits: any = [];
  american_hits: any = [];
  bbq_hits: any = [];
  mexican_hits: any = [];
  top_hits: any = [];
  latlng = {
    lat: 0,
    lng: 0
  };
  pages_1 = 1;
  pages_1_numbers: any = [];
  testing = [];
  collection: any;
  showHits = false;
  booking = false;
  pickup = false;
  cuisines: any = [];
  cuisine_filter = false;
  distance_filter = false;
  date_filter = false;
  cuisine_filter_value: any = null;
  distance_filter_value: any = null;
  date_filter_value: any = null;
  skeletons_grids: any = [1, 2, 3, 4, 5];
  search_skeleton = false;

  constructor(
    private algoliaService: AlgoliaFireService,
    private router: Router) { }

  ngOnInit(): void {
    this.findAll();
  }

  async findAll() {
    // let lat_lng_filters = {};
    // if (this.latlng.lat != 0 && this.latlng.lng != 0) {
    //   lat_lng_filters = {
    //     ...lat_lng_filters,
    //     aroundLatLng: `${this.latlng.lat}, ${this.latlng.lng}`
    //   }
    // }
    // if (this.distance_filter_value !== null) {
    //   lat_lng_filters = {
    //     ...lat_lng_filters,
    //     aroundRadius: this.distance_filter_value,
    //   }
    // }
    try {
      this.search_skeleton = true;
      const result = await this.algoliaService.index.search('', {
        // attributesToRetrieve: ['cuisineTypes'],
        // filters: `${this.cuisine_filter_value !== null ? this.cuisine_filter_value : ''}`,
        // getRankingInfo: true,
        // hitsPerPage: 5,
        // ...lat_lng_filters
      });
      this.search_skeleton = false;
      this.hits = result?.hits;
    } catch (error: any) {
      this.search_skeleton = false;
    }
  }

  viewDetails(truck: any) {
    return this.router.navigateByUrl(`/truck/details/${truck.objectID}/${truck?._rankingInfo?.geoDistance}`);
  }

}
