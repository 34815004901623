import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  geoCoder: any;

  constructor(
    private mapsAPILoader: MapsAPILoader,
  ) {

  }

  initGeoCoder() {
    return this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      this.geoCoder.componentRestrictions = {
        country: ['US']
      }
    })
  }

  get getGeocoder() {
    return this.geoCoder;
  }
}
