import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  NgZone,
  AfterViewInit,
} from '@angular/core';
import { TrucksService } from 'src/app/trucks/services/trucks.service';
import { Router } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { GoogleService } from '../../services/google.service';
import { DeviceDetectorService, OS } from 'ngx-device-detector';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  lat: any = 0;
  lng: any = 0;
  current_address: any = '';
  options: any = {
    componentRestrictions: { country: ['US'] },
  };
  isAddressSelected = false;
  session_address: any = '';
  geoCoder: any;
  @ViewChild('google_ref') searchElementRef: ElementRef;

  public appLinkPlayStore =
    'https://play.google.com/store/apps/details?id=com.guttrucks.client';
  public appLinkAppStore = 'https://apps.apple.com/us/app/guttrucks/1602980633';

  constructor(
    public truckService: TrucksService,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private googleService: GoogleService,
    private deviceDetector: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    sessionStorage.getItem('address')
      ? ((this.isAddressSelected = true),
        (this.session_address = sessionStorage.getItem('address')))
      : (this.isAddressSelected = false);
  }

  ngAfterViewInit(): void {
    this.mapsAPILoader.load().then(() => {
      if (google.maps.places && google) {
        this.geoCoder = new google.maps.Geocoder();
        this.geoCoder.componentRestrictions = {
          country: ['US'],
        };
        let autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement,
          this.options
        );
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            this.handleAddressChange(place);
          });
        });
      }
    });
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            this.current_address = results[0].formatted_address;
            sessionStorage.setItem('address', this.current_address);
            setTimeout(() => {
              this.router.navigateByUrl('/search');
            }, 200);
            // console.log(results[0].formatted_address);
          } else {
            this.current_address = 'Unknown Address';
            sessionStorage.setItem('address', this.current_address);
            console.log('No results found');
            setTimeout(() => {
              this.router.navigateByUrl('/search');
            }, 200);
          }
        } else {
          this.current_address = 'Unknown Address';
          sessionStorage.setItem('address', this.current_address);
          console.log('Geocoder failed due to: ' + status);
          setTimeout(() => {
            this.router.navigateByUrl('/search');
          }, 200);
        }
      }
    );
  }

  public handleAddressChange(address: any) {
    this.lat = address?.geometry?.location?.lat();
    this.lng = address?.geometry?.location?.lng();
    this.current_address = address?.formatted_address;
    this.truckService.isInArr(address?.formatted_address)
      ? null
      : this.truckService.previous_directions.unshift(address);
    sessionStorage.setItem('lat', this.lat.toString());
    sessionStorage.setItem('lng', this.lng.toString());
    sessionStorage.setItem('address', this.current_address);
    this.session_address = sessionStorage.getItem('address');
    setTimeout(() => {
      this.router.navigateByUrl('/search');
    }, 200);
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((res) => {
        this.lat = res.coords.latitude;
        this.lng = res.coords.longitude;
        this.current_address = 'Device location';
        // this.truckService.isInArr(this.current_address) ? null : this.truckService.previous_directions.unshift({ formatted_address: this.current_address });
        sessionStorage.setItem('lat', this.lat.toString());
        sessionStorage.setItem('lng', this.lng.toString());
        this.getAddress(this.lat, this.lng);
      });
    }
  }

  navigateTo(url: string) {
    window.location.href = url;
  }

  public redirectToAppStore() {
    if (this.deviceDetector.isMobile() || this.deviceDetector.isTablet()) {
      if (this.deviceDetector.os === OS.IOS) {
        this.navigateTo(this.appLinkAppStore);
      } else {
        this.navigateTo(this.appLinkPlayStore);
      }
    } else if (this.deviceDetector.isDesktop()) {
      if (this.deviceDetector.os === OS.MAC) {
        this.navigateTo(this.appLinkAppStore);
      } else {
        this.navigateTo(this.appLinkPlayStore);
      }
    }
  }

}
