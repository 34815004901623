<section class="relative
us:w-94vw us:p-3 us:h-auto
us-landscape:overflow-auto us-landscape:h-80
md:w-94vw p-padding-4-md md:h-auto
md-landscape:h-auto
lg:
lg-landscape:pt-padding-6 lg-landscape:pl-padding-11 lg-landscape:pb-padding-11 lg-landscape:pr-padding-11 lg-landscape:w-60vw lg-landscape:h-auto
xl-landscape:w-50vw
">
  <div class="icons relative w-full flex justify-between items-center h-auto
                lg-landscape:">
    <span (click)="closeWithData()" class="icon-arrow-back-round-24px-2 cursor-pointer
    us:text-xl
    us-landscape:
    md:text-3xl
    md-landscape:
    lg:
    lg-landscape:text-1.3vw
    xl-landscape:"></span>
    <span (click)="close()" class="icon-close-round-24px-2 cursor-pointer
    us:text-xl
    us-landscape:
    md:text-3xl
    md-landscape:
    lg:
    lg-landscape:text-1.3vw
    xl-landscape:"></span>
  </div>
  <div class="relative flex justify-center items-center
  us:mt-1
  us-landscape:
  md:mt-margin-2
  md-landscape:
  lg:
  lg-landscape:mt-margin-4
  xl-landscape:">
    <p class="relative text-gray-ababab font-PoppinsRegular
    us:text-lg
  us-landscape:
  md:text-2xl md:font-PoppinsBold
  md-landscape:
  lg:
  lg-landscape:text-font-32 lg-landscape:leading-leading-48
  xl-landscape:">{{current_address}}</p>
  </div>
  <div class="relative w-full flex items-center
  us:mt-3
  us-landscape:
  md:mt-margin-6-md
  md-landscape:
  lg:
  lg-landscape:mt-1vw
  xl-landscape:">
    <!-- <input ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
      (onAddressChange)="handleAddressChange($event)" type="text" class="relative w-full h-3vw border font-PoppinsRegular border-gray-777777 rounded-0.41vw focus:outline-none focus:ring-0 bg-white
lg-landscape:text-1.04vw"> -->
    <!-- <input type="text" ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
      (onAddressChange)="handleAddressChange($event)" placeholder="New address" class="relative w-full h-3vw border border-gray-777777 focus:outline-none focus:ring-0 bg-white text-gray-333333
      us:text-base us:font-PoppinsMedium us:h-9 us:rounded-md
      us-landscape:
      md:text-font-20-md md:leading-leading-30-md md:h-16 md:rounded-lg md:font-PoppinsMedium
      md-landscape:
      lg:
      lg-landscape:text-xl lg-landscape:rounded-0.41vw lg-landscape:font-PoppinsRegular
      xl-landscape:"> -->
    <input type="text" #map_google_ref placeholder="New address" class="relative w-full h-3vw border border-gray-777777 focus:outline-none focus:ring-0 bg-white text-gray-333333
      us:text-base us:font-PoppinsMedium us:h-9 us:rounded-md
      us-landscape:
      md:text-font-20-md md:leading-leading-30-md md:h-16 md:rounded-lg md:font-PoppinsMedium
      md-landscape:
      lg:
      lg-landscape:text-xl lg-landscape:rounded-0.41vw lg-landscape:font-PoppinsRegular
      xl-landscape:">
    <span class="absolute icon-search-round-24px-1 text-gray-4F4F4F cursor-pointer
    us:right-4 us:text-xl
    us-landscape:
    md:right-5 md:text-3xl
    md-landscape:
    lg:
    lg-landscape:right-5 lg-landscape:text-3xl
    xl-landscape:"></span>
  </div>
  <div class="relative w-full grid
  us:grid-cols-1 us:gap-y-4 us:mt-4
  us-landscape:
  md:grid-cols-2 md:gap-x-10 md:mt-margin-10.2-md md:h-height-91.2
  md-landscape:
  lg:
  lg-landscape:grid-cols-2 lg-landscape:gap-x-gap-7 lg-landscape:mt-2.08vw lg-landscape:h-19vw
  xl-landscape:">
    <div class="relative">
      <div class="relative flex items-center
      us:gap-x-2 us:mb-2
      us-landscape:
      md:gap-x-4 md:mb-8
      md-landscape:
      lg:
      lg-landscape:gap-x-gap-4 lg-landscape:mb-margin-8
      xl-landscape:">
        <span class="icon-navigation-round-24px-2 text-red-primary
        us:text-2xl
        us-landscape:
        md:text-3xl
        md-landscape:
        lg:
        lg-landscape:text-3xl
        xl-landscape:"></span>
        <p (click)="getCurrentLocation()" class="relative cursor-pointer font-PoppinsMedium text-gray-333333
        us:text-sm
        us-landscape:
        md:text-font-14-md md:leading-leading-21-md
        md-landscape:
        lg:
        lg-landscape:text-sm
        xl-landscape:">Use current location</p>
      </div>
      <div class="relative flex flex-col
      us:gap-y-2
      us-landscape:
      md:gap-y-4
      md-landscape:
      lg:
      lg-landscape:gap-y-gap-4
      xl-landscape:">
        <div class="relative flex items-center
        us:gap-x-2
        us-landscape:
        md:gap-x-4
        md-landscape:
        lg:
        lg-landscape:gap-x-gap-4
        xl-landscape:">
          <span class="relative icon-history-round-24px-1 text-red-primary
          us:text-2xl
          us-landscape:
          md:text-3xl
          md-landscape:
          lg:
          lg-landscape:text-3xl
          xl-landscape:"></span>
          <p class="relative font-PoppinsMedium text-gray-333333
          us:text-sm
          us-landscape:
          md:text-font-14-md md:leading-leading-21-md
          md-landscape:
          lg:
          lg-landscape:text-sm
          xl-landscape:">Previous directions</p>
        </div>
        <p (click)="handleAddressChange(item)" *ngFor="let item of truckService.previous_directions" class="relative cursor-pointer text-gray-777777 font-PoppinsRegular
        us:text-xs
        us-landscape:
        md:text-font-13-md md:leading-leading-21-md
        md-landscape:
        lg:
        lg-landscape:text-font-16 leading-leading-24
        xl-landscape:">{{item?.formatted_address}}</p>
      </div>
    </div>
    <div class="relative">
      <div class="relative flex items-center
      us:gap-x-2 us:mb-2
      us-landscape:
      md:gap-x-4 md:mb-8
      md-landscape:
      lg:
      lg-landscape:gap-x-gap-4 lg-landscape:mb-margin-8
      xl-landscape:">
        <span class="relative icon-map-round-24px-2 text-red-primary
        us:text-2xl
        us-landscape:
        md:text-3xl
        md-landscape:
        lg:
        lg-landscape:text-3xl
        xl-landscape:"></span>
        <p class="relative font-PoppinsMedium text-gray-333333
        us:text-sm
        us-landscape:
        md:text-font-14-md md:leading-leading-21-md
        md-landscape:
        lg:
        lg-landscape:text-sm
        xl-landscape:">Set your current location</p>
      </div>
      <div class="relative w-full
      us:h-height-48vw-us
      us-landscape:
      md:h-height-74.5-md
      md-landscape:
      lg:
      lg-landscape:h-height-75.2
      xl-landscape:" id="map"></div>
    </div>

  </div>
</section>