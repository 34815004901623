import { Pipe, PipeTransform } from '@angular/core';
import { getDownloadURL, ref } from '@angular/fire/storage';
import { AlgoliaFireService } from '../services/algolia.service';

@Pipe({
    name: 'gsToHttps'
})

export class GsToHttps implements PipeTransform {

    constructor(private algoliaService: AlgoliaFireService) {

    }

    async transform(url: any) {
        let result = '';
        const gsReference = ref(this.algoliaService.fire_storage, url);
        try {
            result = await getDownloadURL(ref(this.algoliaService.fire_storage, gsReference.fullPath));
        } catch (error) {
            console.log(error);
        }
        return result;
    }
}