<section class="fadeIn slider-2-header relative w-full h-auto overflow-hidden min-h-screen bg-gray-slider-body-back-color
    us-landscape:
    md:mt-10
    md-landscape:
    lg:
    lg-landscape:mt-12 lg-landscape:pl-body-lateral-left-padding-lg lg-landscape:pr-body-lateral-left-padding-lg
    xl-landscape:mt-14">
    <div class="head relative w-full flex items-center justify-start border-b-2 border-red-primary
  us:mb-4
  us-landscape:
  md:h-auto md:mb-6
  md-landscape:
  lg:
  lg-landscape:h-auto lg-landscape:mb-1vw lg-landscape:mt-16
  xl-landscape:
">
        <p class="relative capitalize text-gray-333333 font-PoppinsBold
    us:text-base
    us-landscape:
    md:text-font-20-md md:leading-leading-30-md
    md-landscape:
    lg:
    lg-landscape:
    xl-landscape:text-2xl">Results</p>
    </div>
    <div class="body w-full h-auto overflow-hidden bg-gray-slider-body-back-color
  us:pt-4 us:pb-4 us:pl-4 us:pr-4
  us-landscape:
  md:pt-padding-4-md md:pl-padding-4-md md:pr-padding-4-md md:pb-padding-4-md
  md-landscape:
  lg:
  lg-landscape:pt-padding-8 lg-landscape:pl-padding-5 lg-landscape:pr-padding-5 lg-landscape:pb-padding-4 lg-landscape:rounded-sm
  xl-landscape:rounded-md
">
        <div *ngIf="!search_skeleton" class="relative w-full h-auto grid
      us:grid-cols-1 us:gap-y-3
      us-landscape:
      md:grid-cols-2 md:gap-x-gap-6.5 md:gap-y-3
      md-landscape:
      lg:
      lg-landscape:grid-cols-5 lg-landscape:gap-x-2 lg-landscape:gap-y-3
      xl-landscape:gap-y-4 xl-landscape:gap-x-3">
            <div (click)="viewDetails(item)" *ngFor="let item of hits" class="card relative w-auto bg-white cursor-pointer
         us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
         us-landscape:
         md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
         md-landscape:
         lg:
         lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
         xl-landscape:rounded-2xl">
                <img class="relative w-full object-cover rounded-lg h-55%" src="{{item?.thumbnail | gsToHttps | async}}"
                    alt="">
                <p class="relative font-PoppinsBold
            us:mt-2.5 us:text-base
            us-landscape:
            md:text-base md:mt-2.5
            md-landscape:
            lg:
            lg-landscape:text-text-base lg-landscape:mt-margin-3
            xl-landscape:
  ">{{item?.name}}</p>
                <p class="relative font-PoppinsRegular text-gray-card-clear-gray-text
            us:text-xs us:mt-2
            us-landscape:
            md:text-xs md:mt-2
            md-landscape:
            lg:
            lg-landscape:text-tipology-text lg-landscape:mt-margin-top-tipology-cards
            xl-landscape:
  ">{{item?.cuisineTypes | getCuisineGeneralType | async}}</p>
                <div class="relative h-2px bg-pink-dark
               us:w-width-17.1-md us:mt-5
               us-landscape:
               md:w-width-17.1-md md:mt-5
               md-landscape:
               lg:
               lg-landscape:w-red-line-cards lg-landscape:mt-margin-top-line-card
               xl-landscape:
  "></div>
                <div class="relative w-full h-auto flex justify-between items-center
            us:mt-2.5
            us-landscape:
            md:mt-2.5
            md-landscape:
            lg:
            lg-landscape:mt-margin-3
            xl-landscape:">
                    <div class="relative flex">
                        <div class="flex items-center">
                            <img class="relative
              us:w-3.5 us:h-4
              us-landscape:
              md:w-3.5 md:h-4
              md-landscape:
              lg:
              lg-landscape:w-icon-location-width lg-landscape:h-icon-first-card
              xl-landscape:" src="../../../../assets/icons/pin.svg" alt="">
                            <p class="relative font-PoppinsLight text-black
                        us:text-sm us:ml-1
                        us-landscape:
                        md:text-sm md:ml-1
                        md-landscape:
                        lg:
                        lg-landscape:text-xs lg-landscape:ml-2
                        xl-landscape:text-sm">{{item?._rankingInfo?.geoDistance |
                                twoDecimalsMetersToMiles}} miles</p>
                        </div>
                        <div class="flex items-center
          us:ml-5
          us-landscape:
          md:ml-5
          md-landscape:
          lg:
          lg-landscape:ml-margin-icon-card
          xl-landscape:">
                            <!-- <img class="relative
              us:w-width-4.1-md us:h-4
              us-landscape:
              md:w-width-4.1-md md:h-4
              md-landscape:
              lg:
              lg-landscape:w-icon-star-width lg-landscape:h-icon-star
              xl-landscape:" src="../../../../assets/icons/star.svg" alt="">
<p class="relative font-PoppinsLight text-black
                        us:text-sm us:ml-1
                        us-landscape:
                        md:text-sm md:ml-1
                        md-landscape:
                        lg:
                        lg-landscape:text-xs lg-landscape:ml-2
                        xl-landscape:text-sm">{{item?.rating}}</p> -->
                        </div>
                    </div>
                    <div class="flex items-center
      us:
      us-landscape:
      md:
      md-landscape:
      lg:
      lg-landscape:ml-margin-icon-card
      xl-landscape:">
                        <!-- <img class="relative
          us:w-4 us:h-height-4.5-md
          us-landscape:
          md:w-4 md:h-height-4.5-md
          md-landscape:
          lg:
          lg-landscape:w-icon-cards-width lg-landscape:h-icon-first-card
          xl-landscape:" src="../../../../assets/icons/share.svg" alt="">
<img class="relative
          us:w-5 us:h-height-4.5-md us:ml-5
          us-landscape:
          md:w-5 md:h-height-4.5-md md:ml-5
          md-landscape:
          lg:
          lg-landscape:w-icon-heart-width lg-landscape:h-icon-first-card lg-landscape:ml-margin-icon-heart
          xl-landscape:" src="../../../../assets/icons/filled-heart.svg" alt=""> -->
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="search_skeleton" class="relative w-full h-auto grid
us:grid-cols-1 us:gap-y-3
us-landscape:
md:grid-cols-2 md:gap-x-gap-6.5 md:gap-y-3
md-landscape:
lg:
lg-landscape:grid-cols-5 lg-landscape:gap-x-2 lg-landscape:gap-y-3
xl-landscape:gap-y-4 xl-landscape:gap-x-3">
            <div *ngFor="let item of skeletons_grids" class="card relative w-auto bg-white cursor-pointer
us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
us-landscape:
md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
md-landscape:
lg:
lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
xl-landscape:rounded-2xl">
                <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
'border-radius': '10px',
'height': '55%',
'width':'100%'
}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
'width':'100%'
}"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
'width':'50%'
}"></ngx-skeleton-loader>
                <div class="relative w-full">
                    <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
'width':'30%'
}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
'width':'30%',
'margin-left':'3%'
}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
'width':'30%',
'margin-left':'3%'
}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</section>