import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationComponent } from '../components/notification/notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public userStatus = new Subject<boolean>();

  constructor(private dialog: MatDialog, private router: Router) { }

  getUserStatus() {
    return this.userStatus.asObservable();
  }

  showNotification(type: string, msg: string, btn_text: string) {
    let dialoRef = this.dialog.open(NotificationComponent, {
      backdropClass: 'custom-backdrop',
      disableClose: true,
      // width:'auto',
      data: {
        type: type,
        msg: msg,
        btn_text: btn_text
      }
    })

    dialoRef.afterClosed().subscribe({
      next: (data: any) => {
        if (data == true) {
          this.router.navigateByUrl('/home');
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }

  showAndSubscribe(type: string, msg: string, btn_text: string) {
    return this.dialog.open(NotificationComponent, {
      backdropClass: 'custom-backdrop',
      disableClose: true,
      // width:'auto',
      data: {
        type: type,
        msg: msg,
        btn_text: btn_text
      }
    })
  }
}
