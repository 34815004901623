import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as L from 'leaflet';

@Component({
  selector: 'app-modal-map',
  templateUrl: './modal-map.component.html',
  styleUrls: ['./modal-map.component.scss']
})
export class ModalMapComponent implements OnInit, AfterViewInit {
  map: any;

  constructor(
    private dialogRef: MatDialogRef<ModalMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { lat: any, lng: any },
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.loadMap();
  }

  loadMap(): void {

    this.map = L.map('modal-map')
      .setView(
        [this.data?.lat, this.data?.lng],
        10);
    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      // attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 20,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoiYWN1Y3RlYyIsImEiOiJja2xkYWlzOWgwZ28zMnhwN2h6MTExY2FwIn0.xA8q4gZl2qVnbXdneSFRig',
    }).addTo(this.map);
    this.createMarker(this.data?.lat, this.data?.lng);
  }

  createMarker(lat: any, lng: any) {
    // console.log(data);
    const icon = L.icon({
      iconUrl: '../../../../assets/icons/map/marker-icon.png',
      iconSize: [25, 41],
      iconAnchor: [12.5, 41],
      // shadowUrl: 'assets/images/marker-shadow.png',
      popupAnchor: [0, -41],
    });
    const marker = L.marker([lat, lng], { icon })
      .bindPopup('Device location', {
        closeButton: false
      });
    marker.addTo(this.map);
  }

  close() {
    this.dialogRef.close();
  }

}
