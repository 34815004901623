import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  // @ViewChild("searchInput", { static: false }) fileInput: ElementRef;

  constructor() {

  }

  ngOnInit(): void {
    // navigator.geolocation.watchPosition(res => {
    //   console.log(res.coords.latitude);
    //   console.log(res.coords.longitude);
    // })
  }

  

}
