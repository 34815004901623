<!-- <input type="text" (keyup)="search($event)">
<div class="relative" *ngFor="let hit of hits">
    <p class="relative">{{hit?.name}}</p>
    <p class="relative" [innerHTML]="hit?._highlightResult?.name?.value"></p>
</div> -->
<div class="relative" id="search-body">
  <section id="breadcrumb" class="relative w-auto flex
      us:mt-28 us:items-center us:mb-3
      us-landscape:
      md:mt-32 md:items-center
      md-landscape:
      lg:
      lg-landscape:mb-8
      xl-landscape:mb-10">
    <a href="https://guttrucks.com/" class="relative flex justify-center items-center bg-gray-efefef
      us:w-7 us:h-7 us:rounded-md us:mr-2
      us-landscape:
      md:w-8 md:h-8 md:rounded-md md:mr-2
      md-landscape:
      lg:
      lg-landscape:hidden
      xl-landscape:">
      <img class="realtive w-40% transform rotate-180" src="../../../../assets/icons/caret-right.svg" alt="">
    </a>
    <p class="relative uppercase font-PoppinsBold text-gray-333333
      us:text-font-13-md us:leading-leading-21-md
      us-landscape:
      md:text-font-13-md md:leading-leading-21-md
      md-landscape:
      lg:
      lg-landscape:text-.73vw
      xl-landscape:text-sm">search</p>
    <p class="relative font-PoppinsBold ml-3 mr-3 text-gray-333333
    us:text-font-13-md us:leading-leading-21-md
    md:text-font-13-md md:leading-leading-21-md
      lg-landscape:text-.7vw">/</p>
    <p class="relative font-PoppinsBold text-gray-777777 capitalize
      us:text-font-13-md us:leading-leading-21-md
      us-landscape:
      md:text-font-13-md md:leading-leading-21-md
      md-landscape:
      lg:
      lg-landscape:text-.73vw
      xl-landscape:text-sm">search result</p>
  </section>

  <section class="fadeIn search w-full h-auto flex justify-between
  us:hidden
  us-landscape:
  md:hidden
  md-landscape:
  lg:
  lg-landscape:inline-flex
  xl-landscape">
    <a href="https://guttrucks.com/" class="relative flex justify-center items-center bg-gray-efefef
      us:
      us-landscape:
      md:hidden
      md-landscape:
      lg:
      lg-landscape:w-2.08vw lg-landscape:h-2.08vw lg-landscape:rounded-0.41vw lg-landscape:inline-flex
      xl-landscape:">
      <img class="realtive w-40% transform rotate-180" src="../../../../assets/icons/caret-right.svg" alt="">
    </a>
    <div class="search-input-container relative flex items-center justify-start overflow-hidden box-border bg-gray-efefef
      us:
      us-landscape:
      md:
      md-landscape:
      lg:
      lg-landscape:h-5.8vw lg-landscape:w-95% lg-landscape:rounded-0.41vw
      xl-landscape:">
      <div class="relative h-full flex justify-start items-center
                      us:
                      us-landscape:
                      md:
                      md-landscape:
                      lg:
                      lg-landscape:w-60% lg-landscape:ml-5
                      xl-landscape:ml-5">
        <input type="text" (keyup)="search($event)" class="relative font-PoppinsMedium text-black border-none focus:border-none focus:outline-none focus:ring-0 bg-white
                         us:
                         us-landscape:
                         md:
                         md-landscape:
                         lg:
                         lg-landscape:h-full-hd-search-box-input-height lg-landscape:rounded-full
                         lg-landscape:text-base lg-landscape:pl-4 lg-landscape:pr-12 lg-landscape:w-full
                         xl-landscape:h-full-hd-search-box-input-height xl-landscape:rounded-full
                         xl-landscape:text-base xl-landscape:pl-4 xl-landscape:pr-12 xl-landscape:w-full">
        <span class="icon-search-round-24px-1 text-gray-4F4F4F absolute right-5 cursor-pointer
                         us:
                         us-landscape:
                         md:
                         md-landscape:
                         lg:
                         lg-landscape:text-1.6vw
                         xl-landscape:"></span>
      </div>
      <div class="relative flex justify-start items-center
                      us:
                      us-landscape:
                      md:
                      md-landscape:
                      lg:
                      lg-landscape:h-full-hd-search-box-input-height lg-landscape:w-40%
                      xl-landscape:">
        <span class="relative
                  us:
                  us-landscape:
                  md:
                  md-landscape:
                  lg:
                  lg-landscape:ml-2.08vw lg-landscape:mr-2.08vw lg-landscape:border-l-2 lg-landscape:border-gray-333333 lg-landscape:h-full-hd-search-vertical-line-height
                  xl-landscape:border-l-2 xl-landscape:border-gray-333333 xl-landscape:h-full-hd-search-vertical-line-height
                  xl-landscape:"></span>
        <div class="relative h-full flex justify-start items-center
                  us:
                  us-landscape:
                  md:
                  md-landscape:
                  lg:
                  lg-landscape:w-full
                  xl-landscape:">
          <span (click)="showOnMap()" class="icon-pin-drop-round-24px-2 text-red-primary cursor-pointer
                  us:
                  us-landscape:
                  md:
                  md-landscape:
                  lg:
                  lg-landscape:text-1.6vw lg-landscape:mr-image-1-margin
                  xl-landscape:"></span>
          <p (click)="getDeviceLocation()" class="relative text-center cursor-pointer font-PoppinsMedium text-red-primary line-clamp-1
                  us:
                  us-landscape:
                  md:
                  md-landscape:
                  lg:
                  lg-landscape:text-search-location-text lg-landscape:w-60%
                  xl-landscape:">{{address}}</p>
          <span (click)="selectOnMap()" class="icon-edit-round-24px-1 text-black cursor-pointer
                  us:
                  us-landscape:
                  md:
                  md-landscape:
                  lg:
                  lg-landscape:text-1.3vw lg-landscape:ml-image-1-margin
                  xl-landscape:"></span>
        </div>
      </div>
    </div>
  </section>

  <section id="md-search" class="relative bg-gray-slider-body-back-color
  us:p-3 us:mt-6 us:rounded-lg
  us-landscape:
  md:p-4 md:mt-6 md:rounded-lg
  md-landscape:
  lg:
  lg-landscape:hidden
  xl-landscape:">
    <div class="relative flex justify-between items-center">
      <span (click)="showOnMap()" class="icon-pin-drop-round-24px-2 text-red-primary
      us:text-xl
      us-landscape:
      md:text-3xl
      md-landscape:
      lg:
      lg-landscape:
      xl-landscape:"></span>
      <p class="relative font-PoppinsMedium text-red-primary
      us:text-sm
      us-landscape:
      md:text-base
      md-landscape:
      lg:
      lg-landscape:
      xl-landscape:">{{address}}</p>
      <span (click)="selectOnMap()" class="icon-edit-round-24px-1 text-red-primary
      us:text-xl
      us-landscape:
      md:text-3xl
      md-landscape:
      lg:
      lg-landscape:
      xl-landscape:"></span>
    </div>

    <div class="relative">
      <div class="relative h-full flex justify-start items-center
                      us:mt-4
                      us-landscape:
                      md:mt-6
                      md-landscape:
                      lg:
                      lg-landscape:
                      xl-landscape:">
        <input type="text" placeholder="Ready to search" (keyup)="search($event)" value="" class="relative font-PoppinsMedium text-black border-none focus:border-none focus:outline-none focus:ring-0 bg-white
                         us:h-12 us:w-full us:rounded-lg us:text-sm
                         us-landscape:
                         md:h-14 md:w-full md:rounded-lg md:text-base
                         md-landscape:
                         lg:
                         lg-landscape:
                         xl-landscape:">
        <span class="icon-search-round-24px-1 text-gray-4F4F4F absolute right-5 cursor-pointer
                         us:text-xl
                         us-landscape:
                         md:text-2xl
                         md-landscape:
                         lg:
                         lg-landscape:
                         xl-landscape:"></span>
      </div>
    </div>
  </section>

  <div *ngIf="hits.length == 0" class="relative w-full bg-gray-f7f7f7
            us:
            us-landscape:
            md:hidden
            md-landscape:
            lg:mt-113px-lg lg:mb-83px-lg lg:h-199px-lg lg:pl-47px-lg lg:inline-block
            lg-landscape:mt-113px lg-landscape:mb-103px lg-landscape:h-250px lg-landscape:pl-47px lg-landscape:inline-block
            xl-landscape:inline-block">
    <div class="relative flex justify-between">
      <div class="relative
            us:
            us-landscape:
            md:
            md-landscape:
            lg:mt-8
            lg-landscape:mt-margin-8
            xl-landscape:">
        <p class="relative uppercase leading-tight font-bold text-black-1f1f1f
            us:
            us-landscape:
            md:
            md-landscape:
            lg:text-font-44-lg
            lg-landscape:text-font-64
            xl-landscape:">Food Truck Experience</p>
        <p class="relative tracking-tight font-light text-red-primary
            us:
            us-landscape:
            md:
            md-landscape:
            lg:text-font-33-lg
            lg-landscape:text-font-48
            xl-landscape:">Choose from a Wide Selection of Food Trucks</p>
      </div>
    </div>
    <div class="absolute
            us:
            us-landscape:
            md:
            md-landscape:
            lg:-bottom-44px-lg lg:right-0
            lg-landscape:-bottom-44px lg-landscape:right-0
            xl-landscape:">
      <img class="relative
            us:
            us-landscape:
            md:
            md-landscape:
            lg:w-336px-lg lg:h-299px-lg
            lg-landscape:w-438px lg-landscape:h-371px
            xl-landscape:" src="../../../../assets/imgs/vanCOLORS 1 (2).png" alt="">
    </div>
    <button routerLink="/all" class="absolute bg-red-primary rounded-lg text-gray-FFFFFF font-PoppinsRegular tracking-wider font-semibold
            us:
            us-landscape:
            md:
            md-landscape:
            lg:-bottom-28px-lg lg:left-257px-lg lg:w-233px-lg lg:h-51px-lg lg:text-lg
            lg-landscape:-bottom-28px lg-landscape:left-361px lg-landscape:w-284px lg-landscape:h-62px lg-landscape:text-font-20
            xl-landscape:">See all foodtrucks</button>
  </div>

  <section *ngIf="hits.length > 0" class="fadeIn slider-2-header relative w-full h-auto overflow-hidden
                    us:mt-7
                    us-landscape:
                    md:mt-10
                    md-landscape:
                    lg:
                    lg-landscape:mt-12
                    xl-landscape:mt-14">
    <div class="head relative w-full flex items-center justify-between border-b-2 border-red-primary
                  us:mb-4
                  us-landscape:
                  md:h-auto  md:mb-6
                  md-landscape:
                  lg:
                  lg-landscape:h-auto lg-landscape:mb-1vw
                  xl-landscape:
      ">
      <p class="relative capitalize text-gray-333333 font-PoppinsBold
                    us:text-base
                    us-landscape:
                    md:text-font-20-md md:leading-leading-30-md
                    md-landscape:
                    lg:
                    lg-landscape:
                    xl-landscape:text-2xl">Search results</p>
      <p (click)="searchWithoutPagination()" class="relative cursor-pointer text-gray-333333 font-PoppinsBold uppercase
                    us:text-sm
                    us-landscape:
                    md:text-font-14-md md:leading-leading-21-md
                    md-landscape:
                    lg:
                    lg-landscape:text-.7vw
                    xl-landscape:">see all</p>
    </div>
    <div class="body w-full h-auto overflow-hidden bg-gray-slider-body-back-color
                  us:pt-4 us:pb-4 us:pl-4 us:pr-4
                  us-landscape:
                  md:pt-padding-4-md md:pl-padding-4-md md:pr-padding-4-md md:pb-padding-4-md
                  md-landscape:
                  lg:
                  lg-landscape:pt-padding-8 lg-landscape:pl-padding-5 lg-landscape:pr-padding-5 lg-landscape:pb-padding-4 lg-landscape:rounded-sm
                  xl-landscape:rounded-md
      ">
      <div *ngIf="!search_skeleton" class="relative w-full h-auto grid
                      us:grid-cols-1 us:gap-y-3
                      us-landscape:
                      md:grid-cols-2 md:gap-x-gap-6.5 md:gap-y-3
                      md-landscape:
                      lg:
                      lg-landscape:grid-cols-5 lg-landscape:gap-x-2 lg-landscape:gap-y-3
                      xl-landscape:gap-y-4 xl-landscape:gap-x-3">
        <div (click)="viewDetails(item)" *ngFor="let item of hits" class="card relative w-auto bg-white cursor-pointer
                         us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
                         us-landscape:
                         md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
                         md-landscape:
                         lg:
                         lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
                         xl-landscape:rounded-2xl">
          <img class="relative w-full object-cover rounded-lg h-55%" src="{{item?.thumbnail | gsToHttps | async}}"
            alt="">
          <p class="relative font-PoppinsBold
                            us:mt-2.5 us:text-base
                            us-landscape:
                            md:text-base md:mt-2.5
                            md-landscape:
                            lg:
                            lg-landscape:text-text-base lg-landscape:mt-margin-3
                            xl-landscape:
                  ">{{item?.name}}</p>
          <p class="relative font-PoppinsRegular text-gray-card-clear-gray-text
                            us:text-xs us:mt-2
                            us-landscape:
                            md:text-xs md:mt-2
                            md-landscape:
                            lg:
                            lg-landscape:text-tipology-text lg-landscape:mt-margin-top-tipology-cards
                            xl-landscape:
                  ">{{item?.cuisineTypes | getCuisineGeneralType | async}}</p>
          <div class="relative h-2px bg-pink-dark
                               us:w-width-17.1-md us:mt-5
                               us-landscape:
                               md:w-width-17.1-md md:mt-5
                               md-landscape:
                               lg:
                               lg-landscape:w-red-line-cards lg-landscape:mt-margin-top-line-card
                               xl-landscape:
                  "></div>
          <div class="relative w-full h-auto flex justify-between items-center
                            us:mt-2.5
                            us-landscape:
                            md:mt-2.5
                            md-landscape:
                            lg:
                            lg-landscape:mt-margin-3
                            xl-landscape:">
            <div class="relative flex">
              <div class="flex items-center">
                <img class="relative
                              us:w-3.5 us:h-4
                              us-landscape:
                              md:w-3.5 md:h-4
                              md-landscape:
                              lg:
                              lg-landscape:w-icon-location-width lg-landscape:h-icon-first-card
                              xl-landscape:" src="../../../../assets/icons/pin.svg" alt="">
                <p class="relative font-PoppinsLight text-black
                                        us:text-sm us:ml-1
                                        us-landscape:
                                        md:text-sm md:ml-1
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-xs lg-landscape:ml-2
                                        xl-landscape:text-sm">{{item?._rankingInfo?.geoDistance |
                  twoDecimalsMetersToMiles}} miles</p>
              </div>
              <div class="flex items-center
                          us:ml-5
                          us-landscape:
                          md:ml-5
                          md-landscape:
                          lg:
                          lg-landscape:ml-margin-icon-card
                          xl-landscape:">
                <!-- <img class="relative
                              us:w-width-4.1-md us:h-4
                              us-landscape:
                              md:w-width-4.1-md md:h-4
                              md-landscape:
                              lg:
                              lg-landscape:w-icon-star-width lg-landscape:h-icon-star
                              xl-landscape:" src="../../../../assets/icons/star.svg" alt="">
                <p class="relative font-PoppinsLight text-black
                                        us:text-sm us:ml-1
                                        us-landscape:
                                        md:text-sm md:ml-1
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-xs lg-landscape:ml-2
                                        xl-landscape:text-sm">{{item?.rating}}</p> -->
              </div>
            </div>
            <div class="flex items-center
                      us:
                      us-landscape:
                      md:
                      md-landscape:
                      lg:
                      lg-landscape:ml-margin-icon-card
                      xl-landscape:">
              <!-- <img class="relative
                          us:w-4 us:h-height-4.5-md
                          us-landscape:
                          md:w-4 md:h-height-4.5-md
                          md-landscape:
                          lg:
                          lg-landscape:w-icon-cards-width lg-landscape:h-icon-first-card
                          xl-landscape:" src="../../../../assets/icons/share.svg" alt="">
              <img class="relative
                          us:w-5 us:h-height-4.5-md us:ml-5
                          us-landscape:
                          md:w-5 md:h-height-4.5-md md:ml-5
                          md-landscape:
                          lg:
                          lg-landscape:w-icon-heart-width lg-landscape:h-icon-first-card lg-landscape:ml-margin-icon-heart
                          xl-landscape:" src="../../../../assets/icons/filled-heart.svg" alt=""> -->
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="search_skeleton" class="relative w-full h-auto grid
      us:grid-cols-1 us:gap-y-3
      us-landscape:
      md:grid-cols-2 md:gap-x-gap-6.5 md:gap-y-3
      md-landscape:
      lg:
      lg-landscape:grid-cols-5 lg-landscape:gap-x-2 lg-landscape:gap-y-3
      xl-landscape:gap-y-4 xl-landscape:gap-x-3">
        <div *ngFor="let item of skeletons_grids" class="card relative w-auto bg-white cursor-pointer
         us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
         us-landscape:
         md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
         md-landscape:
         lg:
         lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
         xl-landscape:rounded-2xl">
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'border-radius': '10px',
            'height': '55%',
            'width':'100%'
            }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'100%'
            }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'50%'
            }"></ngx-skeleton-loader>
          <div class="relative w-full">
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'30%'
            }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'30%',
            'margin-left':'3%'
            }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
          'width':'30%',
          'margin-left':'3%'
          }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="false" class="fadeIn slider-1-header relative w-full h-auto
                    us:mt-7
                    us-landscape:
                    md:mt-10
                    md-landscape:
                    lg:
                    lg-landscape:mt-12
                    xl-landscape:mt-14 ">
    <div class="head relative w-full flex items-center justify-between border-b-2 border-red-primary
                    us:h-auto us:mb-4
                    us-landscape:
                    md:h-auto md:mb-6
                    md-landscape:
                    lg:
                    lg-landscape:h-auto lg-landscape:mb-1vw
                    xl-landscape:
        ">
      <p class="relative capitalize text-gray-333333 font-PoppinsBold
                      us:text-base
                      us-landscape:
                      md:text-font-20-md md:leading-leading-30-md
                      md-landscape:
                      lg:
                      lg-landscape:
                      xl-landscape:text-2xl">Top 10</p>
      <p class="relative text-red-primary font-PoppinsBold uppercase
                      us:text-base
                      us-landscape:
                      md:text-font-20-md md:leading-leading-30-md
                      md-landscape:
                      lg:
                      lg-landscape:text-1vw
                      xl-landscape:">see all</p>
    </div>
    <div class="body w-full h-auto overflow-hidden bg-gray-slider-body-back-color
                  us:pt-4 us:pb-4 us:pl-4 us:pr-4
                  us-landscape:
                  md:pt-padding-4-md md:pl-padding-4-md md:pr-padding-4-md md:pb-padding-4-md
                  md-landscape:
                  lg:
                  lg-landscape:pt-padding-8 lg-landscape:pl-padding-5 lg-landscape:pr-padding-5 lg-landscape:pb-padding-4 lg-landscape:rounded-sm
                  xl-landscape:rounded-md
      ">
      <div class="relative w-full h-auto grid
                      us:grid-cols-1 us:gap-y-3
                      us-landscape:
                      md:gap-x-gap-6.5 md:grid-cols-2 gap-y-3
                      md-landscape:
                      lg:
                      lg-landscape:gap-x-2 lg-landscape:grid-cols-5
                      xl-landscape:gap-x-3" *ngIf="showHits">
        <div (click)="viewDetails(item)" *ngFor="let item of top_hits" class="card relative w-auto bg-white cursor-pointer
                         us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
                         us-landscape:
                         md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
                         md-landscape:
                         lg:
                         lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
                         xl-landscape:rounded-2xl">
          <img class="relative w-full object-cover rounded-lg h-55%" src='{{item?.thumbnail | gsToHttps | async}}'
            alt="">
          <p class="relative font-PoppinsBold
                            us:mt-2.5 us:text-base
                            us-landscape:
                            md:text-base md:mt-2.5
                            md-landscape:
                            lg:
                            lg-landscape:text-text-base lg-landscape:mt-margin-3
                            xl-landscape:
                  ">{{item?.name}}</p>
          <p class="relative font-PoppinsRegular text-gray-card-clear-gray-text
                            us:text-xs us:mt-2
                            us-landscape:
                            md:text-xs md:mt-2
                            md-landscape:
                            lg:
                            lg-landscape:mt-margin-top-tipology-cards lg-landscape:text-tipology-text
                            xl-landscape:
                  ">{{item?.cuisineTypes | getCuisineGeneralType | async}}</p>
          <div class="relative h-2px bg-pink-dark
                               us:w-width-17.1-md us:mt-5
                               us-landscape:
                               md:w-width-17.1-md md:mt-5
                               md-landscape:
                               lg:
                               lg-landscape:mt-margin-top-line-card lg-landscape:w-red-line-cards
                               xl-landscape:
                  "></div>
          <div class="relative w-full h-auto flex justify-between items-center
                            us:mt-2.5
                            us-landscape:
                            md:mt-2.5
                            md-landscape:
                            lg:
                            lg-landscape:mt-margin-top-text-cards
                            xl-landscape:">
            <div class="relative flex">
              <div class="flex items-center">
                <img class="relative
                              us:w-3.5 us:h-4
                              us-landscape:
                              md:w-3.5 md:h-4
                              md-landscape:
                              lg:
                              lg-landscape:w-icon-location-width lg-landscape:h-icon-first-card
                              xl-landscape:" src="../../../../assets/icons/pin.svg" alt="">
                <p class="relative font-PoppinsLight text-black
                                        us:text-sm us:ml-1
                                        us-landscape:
                                        md:text-sm md:ml-1
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-xs lg-landscape:ml-2
                                        xl-landscape:text-sm">{{item?._rankingInfo?.geoDistance |
                  twoDecimalsMetersToMiles}} miles</p>
              </div>
              <div class="flex items-center
                          us:ml-5
                          us-landscape:
                          md:ml-5
                          md-landscape:
                          lg:
                          lg-landscape:ml-margin-icon-card
                          xl-landscape:">
                <!-- <img class="relative
                              us:w-width-4.1-md us:h-4
                              us-landscape:
                              md:w-width-4.1-md md:h-4
                              md-landscape:
                              lg:
                              lg-landscape:w-icon-star-width lg-landscape:h-icon-star
                              xl-landscape:" src="../../../../assets/icons/star.svg" alt="">
                <p class="relative font-PoppinsLight text-black
                                        us:text-sm us:ml-1
                                        us-landscape:
                                        md:text-sm md:ml-1
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-xs lg-landscape:ml-2
                                        xl-landscape:text-sm">{{item?.rating}}</p> -->
              </div>
            </div>
            <div class="flex items-center
                      us:
                      us-landscape:
                      md:
                      md-landscape:
                      lg:
                      lg-landscape:ml-margin-icon-card
                      xl-landscape:">
              <!-- <img class="relative
                          us:w-4 us:h-height-4.5-md
                          us-landscape:
                          md:w-4 md:h-height-4.5-md
                          md-landscape:
                          lg:
                          lg-landscape:w-icon-cards-width lg-landscape:h-icon-first-card
                          xl-landscape:" src="../../../../assets/icons/share.svg" alt="">
              <img class="relative
                          us:w-5 us:h-height-4.5-md us:ml-5
                          us-landscape:
                          md:w-5 md:h-height-4.5-md md:ml-5
                          md-landscape:
                          lg:
                          lg-landscape:w-icon-heart-width lg-landscape:h-icon-first-card lg-landscape:ml-margin-icon-heart
                          xl-landscape:" src="../../../../assets/icons/filled-heart.svg" alt=""> -->
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="top_hits.length == 0" class="relative w-full h-auto grid
          us:grid-cols-1 us:gap-y-3
          us-landscape:
          md:grid-cols-2 md:gap-x-gap-6.5 md:gap-y-3
          md-landscape:
          lg:
          lg-landscape:grid-cols-5 lg-landscape:gap-x-2 lg-landscape:gap-y-3
          xl-landscape:gap-y-4 xl-landscape:gap-x-3">
        <div *ngFor="let item of skeletons_grids" class="card relative w-auto bg-white cursor-pointer
             us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
             us-landscape:
             md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
             md-landscape:
             lg:
             lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
             xl-landscape:rounded-2xl">
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
                'border-radius': '10px',
                'height': '55%',
                'width':'100%'
                }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
                'width':'100%'
                }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
                'width':'50%'
                }"></ngx-skeleton-loader>
          <div class="relative w-full">
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
                'width':'30%'
                }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
                'width':'30%',
                'margin-left':'3%'
                }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
              'width':'30%',
              'margin-left':'3%'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div *ngIf="false" class="fadeIn pagination relative h-auto w-full flex justify-center items-center
  us:
  us-landscape:
  md:
  md-landscape:
  lg:
  lg-landscape:mt-margin-top-line-card
  xl-landscape:">
    <!-- <button class="relative bg-gray-slider-body-back-color flex justify-center items-center
         us:
         us-landscape:
         md:
         md-landscape:
         lg:
         lg-landscape:w-width-11 lg-landscape:h-height-11 lg-landscape:rounded-lg lg-landscape:ml-margin-1 lg-landscape:mr-margin-1
         xl-landscape:rounded-xl">
          <img class="relative
          us:
          us-landscape:
          md:
          md-landscape:
          lg:
          lg-landscape:w-width-2 lg-landscape:h-height-3
          xl-landscape:" src="../../../../assets/icons/slider-left.svg" alt="">
      </button>
      <button *ngFor="let page of pages_1_numbers" (click)="gotoPage(page)" class="relative bg-red-primary text-white flex justify-center items-center font-PoppinsBold
         us:
         us-landscape:
         md:
         md-landscape:
         lg:
         lg-landscape:w-width-11 lg-landscape:h-height-11 lg-landscape:rounded-lg lg-landscape:text-text-base lg-landscape:ml-margin-1 lg-landscape:mr-margin-1
         xl-landscape:xl-landscape:rounded-xl">{{page + 1}}
      </button>
      <button class="relative bg-gray-slider-body-back-color flex justify-center items-center
         us:
         us-landscape:
         md:
         md-landscape:
         lg:
         lg-landscape:w-width-11 lg-landscape:h-height-11 lg-landscape:rounded-lg lg-landscape:ml-margin-1 lg-landscape:mr-margin-1
         xl-landscape:xl-landscape:rounded-xl">
          <img class="relative
         us:
         us-landscape:
         md:
         md-landscape:
         lg:
         lg-landscape:w-width-2 lg-landscape:h-height-3
         xl-landscape:" src="../../../../assets/icons/slider-right.svg" alt="">
      </button>
      <button class="relative bg-gray-slider-body-back-color flex justify-center items-center font-PoppinsBold text-gray-border-bottom-gray
         us:
         us-landscape:
         md:
         md-landscape:
         lg:
         lg-landscape:w-width-11 lg-landscape:h-height-11 lg-landscape:text-text-base lg-landscape:ml-margin-1 lg-landscape:rounded-lg
         xl-landscape:xl-landscape:rounded-xl">...
      </button> -->
    <button *ngFor="let page of pages_1_numbers" (click)="gotoPage(page)" class="relative bg-red-primary text-white flex justify-center items-center font-PoppinsBold
         us:
         us-landscape:
         md:
         md-landscape:
         lg:
         lg-landscape:w-width-11 lg-landscape:h-height-11 lg-landscape:rounded-lg lg-landscape:text-text-base lg-landscape:ml-margin-1 lg-landscape:mr-margin-1
         xl-landscape:xl-landscape:rounded-xl">{{page + 1}}
    </button>
  </div>

  <section class="fadeIn slider-2-header relative w-full h-auto overflow-hidden
                    us:mt-7
                    us-landscape:
                    md:mt-10
                    md-landscape:
                    lg:
                    lg-landscape:mt-12
                    xl-landscape:mt-14">
    <div class="head relative w-full flex items-center justify-between border-b-2 border-red-primary
                  us:h-auto us:mb-4
                  us-landscape:
                  md:mb-6
                  md-landscape:
                  lg:
                  lg-landscape:h-auto lg-landscape:mb-1vw
                  xl-landscape:
      ">
      <p class="relative capitalize text-gray-333333 font-PoppinsBold
                    us:text-base
                    us-landscape:
                    md:text-font-20-md md:leading-leading-30-md
                    md-landscape:
                    lg:
                    lg-landscape:
                    xl-landscape:text-2xl">American</p>
      <p (click)="loadFullAmericanGrid()" class="relative cursor-pointer text-gray-333333 font-PoppinsBold uppercase
                    us:text-sm
                    us-landscape:
                    md:text-font-14-md md:leading-leading-21-md
                    md-landscape:
                    lg:
                    lg-landscape:text-.7vw
                    xl-landscape:">see all</p>
    </div>
    <div class="body w-full h-auto overflow-hidden bg-gray-slider-body-back-color
                  us:pt-4 us:pb-4 us:pl-4 us:pr-4
                  us-landscape:
                  md:pt-padding-4-md md:pl-padding-4-md md:pr-padding-4-md md:pb-padding-4-md
                  md-landscape:
                  lg:
                  lg-landscape:pt-padding-8 lg-landscape:pl-padding-5 lg-landscape:pr-padding-5 lg-landscape:pb-padding-4 lg-landscape:rounded-sm
                  xl-landscape:rounded-md
      ">
      <div class="relative w-full h-auto grid
                      us:grid-cols-1 us:gap-y-3
                      us-landscape:
                      md:grid-cols-2 md:gap-x-gap-6.5 md:gap-y-3
                      md-landscape:
                      lg:
                      lg-landscape:grid-cols-5 lg-landscape:gap-x-2 lg-landscape:gap-y-3
                      xl-landscape:gap-y-4 xl-landscape:gap-x-3">
        <div (click)="viewDetails(item)" *ngFor="let item of american_hits" class="card relative w-auto bg-white cursor-pointer
                         us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
                         us-landscape:
                         md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
                         md-landscape:
                         lg:
                         lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
                         xl-landscape:rounded-2xl">
          <img class="relative w-full object-cover rounded-lg h-55%" src="{{item?.thumbnail | gsToHttps | async}}"
            alt="">
          <p class="relative font-PoppinsBold
                            us:mt-2.5 us:text-base
                            us-landscape:
                            md:text-base md:mt-2.5
                            md-landscape:
                            lg:
                            lg-landscape:text-text-base lg-landscape:mt-margin-3
                            xl-landscape:
                  ">{{item?.name}}</p>
          <p class="relative font-PoppinsRegular text-gray-card-clear-gray-text
                            us:text-xs us:mt-2
                            us-landscape:
                            md:text-xs md:mt-2
                            md-landscape:
                            lg:
                            lg-landscape:text-tipology-text lg-landscape:mt-margin-top-tipology-cards
                            xl-landscape:
                  ">{{item?.cuisineTypes | getCuisineGeneralType | async}}</p>
          <div class="relative h-2px bg-pink-dark
                               us:w-width-17.1-md us:mt-5
                               us-landscape:
                               md:w-width-17.1-md md:mt-5
                               md-landscape:
                               lg:
                               lg-landscape:w-red-line-cards lg-landscape:mt-margin-top-line-card
                               xl-landscape:
                  "></div>
          <div class="relative w-full h-auto flex justify-between items-center
                            us:mt-2.5
                            us-landscape:
                            md:mt-2.5
                            md-landscape:
                            lg:
                            lg-landscape:mt-margin-3
                            xl-landscape:">
            <div class="relative flex">
              <div class="flex items-center">
                <img class="relative
                              us:w-3.5 us:h-4
                              us-landscape:
                              md:w-3.5 md:h-4
                              md-landscape:
                              lg:
                              lg-landscape:w-icon-location-width lg-landscape:h-icon-first-card
                              xl-landscape:" src="../../../../assets/icons/pin.svg" alt="">
                <p class="relative font-PoppinsLight text-black
                                        us:text-sm us:ml-1
                                        us-landscape:
                                        md:text-sm ml-1
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-xs lg-landscape:ml-2
                                        xl-landscape:text-sm">{{item?._rankingInfo?.geoDistance |
                  twoDecimalsMetersToMiles}} miles</p>
              </div>
              <div class="flex items-center
                          us:ml-5
                          us-landscape:
                          md:ml-5
                          md-landscape:
                          lg:
                          lg-landscape:ml-margin-icon-card
                          xl-landscape:">
                <!-- <img class="relative
                              us:us:w-width-4.1-md us:h-4
                              us-landscape:
                              md:w-width-4.1-md md:h-4
                              md-landscape:
                              lg:
                              lg-landscape:w-icon-star-width lg-landscape:h-icon-star
                              xl-landscape:" src="../../../../assets/icons/star.svg" alt="">
                <p class="relative font-PoppinsLight text-black
                                        us:text-sm us:ml-1
                                        us-landscape:
                                        md:text-sm md:ml-1
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-xs lg-landscape:ml-2
                                        xl-landscape:text-sm">{{item?.rating}}</p> -->
              </div>
            </div>
            <div class="flex items-center
                      us:
                      us-landscape:
                      md:
                      md-landscape:
                      lg:
                      lg-landscape:ml-margin-icon-card
                      xl-landscape:">
              <!-- <img class="relative
                          us:w-4 us:h-height-4.5-md
                          us-landscape:
                          md:w-4 md:h-height-4.5-md
                          md-landscape:
                          lg:
                          lg-landscape:w-icon-cards-width lg-landscape:h-icon-first-card
                          xl-landscape:" src="../../../../assets/icons/share.svg" alt="">
              <img class="relative
                          us:w-5 us:h-height-4.5-md us:ml-5
                          us-landscape:
                          md:w-5 md:h-height-4.5-md md:ml-5
                          md-landscape:
                          lg:
                          lg-landscape:w-icon-heart-width lg-landscape:h-icon-first-card lg-landscape:ml-margin-icon-heart
                          xl-landscape:" src="../../../../assets/icons/filled-heart.svg" alt=""> -->
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="american_hits.length == 0" class="relative w-full h-auto grid
          us:grid-cols-1 us:gap-y-3
          us-landscape:
          md:grid-cols-2 md:gap-x-gap-6.5 md:gap-y-3
          md-landscape:
          lg:
          lg-landscape:grid-cols-5 lg-landscape:gap-x-2 lg-landscape:gap-y-3
          xl-landscape:gap-y-4 xl-landscape:gap-x-3">
        <div *ngFor="let item of skeletons_grids" class="card relative w-auto bg-white cursor-pointer
             us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
             us-landscape:
             md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
             md-landscape:
             lg:
             lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
             xl-landscape:rounded-2xl">
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
                'border-radius': '10px',
                'height': '55%',
                'width':'100%'
                }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
                'width':'100%'
                }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
                'width':'50%'
                }"></ngx-skeleton-loader>
          <div class="relative w-full">
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
                'width':'30%'
                }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
                'width':'30%',
                'margin-left':'3%'
                }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
              'width':'30%',
              'margin-left':'3%'
              }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="fadeIn slider-2-header relative w-full h-auto overflow-hidden
                    us:mt-7
                    us-landscape:
                    md:mt-10
                    md-landscape:
                    lg:
                    lg-landscape:mt-12
                    xl-landscape:mt-14">
    <div class="head relative w-full flex items-center justify-between border-b-2 border-red-primary
                  us:h-auto us:mb-4
                  us-landscape:
                  md:mb-6
                  md-landscape:
                  lg:
                  lg-landscape:h-auto lg-landscape:mb-1vw
                  xl-landscape:
      ">
      <p class="relative capitalize text-gray-333333 font-PoppinsBold
                    us:text-base
                    us-landscape:
                    md:text-font-20-md md:leading-leading-30-md
                    md-landscape:
                    lg:
                    lg-landscape:
                    xl-landscape:text-2xl">BBQ</p>
      <p (click)="loadFullBbqGrid()" class="relative cursor-pointer text-gray-333333 font-PoppinsBold uppercase
                    us:text-sm
                    us-landscape:
                    md:text-font-14-md md:leading-leading-21-md
                    md-landscape:
                    lg:
                    lg-landscape:text-.7vw
                    xl-landscape:">see all</p>
    </div>
    <div class="body w-full h-auto overflow-hidden bg-gray-slider-body-back-color
                  us:pt-4 us:pb-4 us:pl-4 us:pr-4
                  us-landscape:
                  md:pt-padding-4-md md:pl-padding-4-md md:pr-padding-4-md md:pb-padding-4-md
                  md-landscape:
                  lg:
                  lg-landscape:pt-padding-8 lg-landscape:pl-padding-5 lg-landscape:pr-padding-5 lg-landscape:pb-padding-4 lg-landscape:rounded-sm
                  xl-landscape:rounded-md
      ">
      <div class="relative w-full h-auto grid
                      us:grid-cols-1 us:gap-y-3
                      us-landscape:
                      md:grid-cols-2 md:gap-x-gap-6.5 md:gap-y-3
                      md-landscape:
                      lg:
                      lg-landscape:grid-cols-5 lg-landscape:gap-x-2 lg-landscape:gap-y-3
                      xl-landscape:gap-y-4 xl-landscape:gap-x-3">
        <div (click)="viewDetails(item)" *ngFor="let item of bbq_hits" class="card relative w-auto bg-white cursor-pointer
                         us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
                         us-landscape:
                         md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
                         md-landscape:
                         lg:
                         lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
                         xl-landscape:rounded-2xl">
          <img class="relative w-full object-cover rounded-lg h-55%" src="{{item?.thumbnail | gsToHttps | async}}"
            alt="">
          <p class="relative font-PoppinsBold
                            us:mt-2.5 us:text-base
                            us-landscape:
                            md:text-base md:mt-2.5
                            md-landscape:
                            lg:
                            lg-landscape:text-text-base lg-landscape:mt-margin-3
                            xl-landscape:
                  ">{{item?.name}}</p>
          <p class="relative font-PoppinsRegular text-gray-card-clear-gray-text
                            us:text-xs us:mt-2
                            us-landscape:
                            md:text-xs md:mt-2
                            md-landscape:
                            lg:
                            lg-landscape:text-tipology-text lg-landscape:mt-margin-top-tipology-cards
                            xl-landscape:
                  ">{{item?.cuisineTypes | getCuisineGeneralType | async}}</p>
          <div class="relative h-2px bg-pink-dark
                               us:w-width-17.1-md us:mt-5
                               us-landscape:
                               md:w-width-17.1-md md:mt-5
                               md-landscape:
                               lg:
                               lg-landscape:w-red-line-cards lg-landscape:mt-margin-top-line-card
                               xl-landscape:
                  "></div>
          <div class="relative w-full h-auto flex justify-between items-center
                            us:mt-2.5
                            us-landscape:
                            md:mt-2.5
                            md-landscape:
                            lg:
                            lg-landscape:mt-margin-3
                            xl-landscape:">
            <div class="relative flex">
              <div class="flex items-center">
                <img class="relative
                              us:w-3.5 us:h-4
                              us-landscape:
                              md:w-3.5 md:h-4
                              md-landscape:
                              lg:
                              lg-landscape:w-icon-location-width lg-landscape:h-icon-first-card
                              xl-landscape:" src="../../../../assets/icons/pin.svg" alt="">
                <p class="relative font-PoppinsLight text-black
                                        us:text-sm us:ml-1
                                        us-landscape:
                                        md:text-sm ml-1
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-xs lg-landscape:ml-2
                                        xl-landscape:text-sm">{{item?._rankingInfo?.geoDistance |
                  twoDecimalsMetersToMiles}} miles</p>
              </div>
              <div class="flex items-center
                          us:ml-5
                          us-landscape:
                          md:ml-5
                          md-landscape:
                          lg:
                          lg-landscape:ml-margin-icon-card
                          xl-landscape:">
                <!-- <img class="relative
                              us:w-width-4.1-md us:h-4
                              us-landscape:
                              md:w-width-4.1-md md:h-4
                              md-landscape:
                              lg:
                              lg-landscape:w-icon-star-width lg-landscape:h-icon-star
                              xl-landscape:" src="../../../../assets/icons/star.svg" alt="">
                <p class="relative font-PoppinsLight text-black
                                        us:text-sm us:ml-1
                                        us-landscape:
                                        md:text-sm md:ml-1
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-xs lg-landscape:ml-2
                                        xl-landscape:text-sm">{{item?.rating}}</p> -->
              </div>
            </div>
            <div class="flex items-center
                      us:
                      us-landscape:
                      md:
                      md-landscape:
                      lg:
                      lg-landscape:ml-margin-icon-card
                      xl-landscape:">
              <!-- <img class="relative
                          us:w-4 us:h-height-4.5-md
                          us-landscape:
                          md:w-4 md:h-height-4.5-md
                          md-landscape:
                          lg:
                          lg-landscape:w-icon-cards-width lg-landscape:h-icon-first-card
                          xl-landscape:" src="../../../../assets/icons/share.svg" alt="">
              <img class="relative
                          us:w-5 us:h-height-4.5-md us:ml-5
                          us-landscape:
                          md:w-5 md:h-height-4.5-md md:ml-5
                          md-landscape:
                          lg:
                          lg-landscape:w-icon-heart-width lg-landscape:h-icon-first-card lg-landscape:ml-margin-icon-heart
                          xl-landscape:" src="../../../../assets/icons/filled-heart.svg" alt=""> -->
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="bbq_hits.length == 0" class="relative w-full h-auto grid
      us:grid-cols-1 us:gap-y-3
      us-landscape:
      md:grid-cols-2 md:gap-x-gap-6.5 md:gap-y-3
      md-landscape:
      lg:
      lg-landscape:grid-cols-5 lg-landscape:gap-x-2 lg-landscape:gap-y-3
      xl-landscape:gap-y-4 xl-landscape:gap-x-3">
        <div *ngFor="let item of skeletons_grids" class="card relative w-auto bg-white cursor-pointer
         us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
         us-landscape:
         md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
         md-landscape:
         lg:
         lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
         xl-landscape:rounded-2xl">
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'border-radius': '10px',
            'height': '55%',
            'width':'100%'
            }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'100%'
            }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'50%'
            }"></ngx-skeleton-loader>
          <div class="relative w-full">
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'30%'
            }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'30%',
            'margin-left':'3%'
            }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
          'width':'30%',
          'margin-left':'3%'
          }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="fadeIn slider-2-header relative w-full h-auto overflow-hidden
                    us:mt-7
                    us-landscape:
                    md:mt-10 md:mb-10
                    md-landscape:
                    lg:
                    lg-landscape:mt-12
                    xl-landscape:mt-14">
    <div class="head relative w-full flex items-center justify-between border-b-2 border-red-primary
                  us:h-auto us:mb-4
                  us-landscape:
                  md:mb-6
                  md-landscape:
                  lg:
                  lg-landscape:h-auto lg-landscape:mb-1vw
                  xl-landscape:
      ">
      <p class="relative capitalize text-gray-333333 font-PoppinsBold
                    us:text-base
                    us-landscape:
                    md:text-font-20-md md:leading-leading-30-md
                    md-landscape:
                    lg:
                    lg-landscape:
                    xl-landscape:text-2xl">Mexican</p>
      <p (click)="loadFullMexicanGrid()" class="relative cursor-pointer text-gray-333333 font-PoppinsBold uppercase
                    us:text-sm
                    us-landscape:
                    md:text-font-14-md md:leading-leading-21-md
                    md-landscape:
                    lg:
                    lg-landscape:text-.7vw
                    xl-landscape:">see all</p>
    </div>
    <div class="body w-full h-auto overflow-hidden bg-gray-slider-body-back-color
                  us:pt-4 us:pb-4 us:pl-4 us:pr-4
                  us-landscape:
                  md:pt-padding-4-md md:pl-padding-4-md md:pr-padding-4-md md:pb-padding-4-md
                  md-landscape:
                  lg:
                  lg-landscape:pt-padding-8 lg-landscape:pl-padding-5 lg-landscape:pr-padding-5 lg-landscape:pb-padding-4 lg-landscape:rounded-sm
                  xl-landscape:rounded-md
      ">
      <div class="relative w-full h-auto grid
                      us:grid-cols-1 us:gap-y-3
                      us-landscape:
                      md:grid-cols-2 md:gap-x-gap-6.5 md:gap-y-3
                      md-landscape:
                      lg:
                      lg-landscape:grid-cols-5 lg-landscape:gap-x-2 lg-landscape:gap-y-3
                      xl-landscape:gap-y-4 xl-landscape:gap-x-3">
        <div (click)="viewDetails(item)" *ngFor="let item of mexican_hits" class="card relative w-auto bg-white cursor-pointer
                         us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
                         us-landscape:
                         md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
                         md-landscape:
                         lg:
                         lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
                         xl-landscape:rounded-2xl">
          <img class="relative w-full object-cover rounded-lg h-55%" src="{{item?.thumbnail | gsToHttps | async}}"
            alt="">
          <p class="relative font-PoppinsBold
                            us:mt-2.5 us:text-base
                            us-landscape:
                            md:text-base md:mt-2.5
                            md-landscape:
                            lg:
                            lg-landscape:text-text-base lg-landscape:mt-margin-3
                            xl-landscape:
                  ">{{item?.name}}</p>
          <p class="relative font-PoppinsRegular text-gray-card-clear-gray-text
                            us:text-xs us:mt-2
                            us-landscape:
                            md:text-xs md:mt-2
                            md-landscape:
                            lg:
                            lg-landscape:text-tipology-text lg-landscape:mt-margin-top-tipology-cards
                            xl-landscape:
                  ">{{item?.cuisineTypes | getCuisineGeneralType | async}}</p>
          <div class="relative h-2px bg-pink-dark
                               us:w-width-17.1-md us:mt-5
                               us-landscape:
                               md:w-width-17.1-md md:mt-5
                               md-landscape:
                               lg:
                               lg-landscape:w-red-line-cards lg-landscape:mt-margin-top-line-card
                               xl-landscape:
                  "></div>
          <div class="relative w-full h-auto flex justify-between items-center
                            us:mt-2.5
                            us-landscape:
                            md:mt-2.5
                            md-landscape:
                            lg:
                            lg-landscape:mt-margin-3
                            xl-landscape:">
            <div class="relative flex">
              <div class="flex items-center">
                <img class="relative
                              us:w-3.5 us:h-4
                              us-landscape:
                              md:w-3.5 md:h-4
                              md-landscape:
                              lg:
                              lg-landscape:w-icon-location-width lg-landscape:h-icon-first-card
                              xl-landscape:" src="../../../../assets/icons/pin.svg" alt="">
                <p class="relative font-PoppinsLight text-black
                                        us:text-sm us:ml-1
                                        us-landscape:
                                        md:text-sm ml-1
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-xs lg-landscape:ml-2
                                        xl-landscape:text-sm">{{item?._rankingInfo?.geoDistance |
                  twoDecimalsMetersToMiles}} miles</p>
              </div>
              <div class="flex items-center
                          us:ml-5
                          us-landscape:
                          md:ml-5
                          md-landscape:
                          lg:
                          lg-landscape:ml-margin-icon-card
                          xl-landscape:">
                <!-- <img class="relative
                              us:w-width-4.1-md us:h-4
                              us-landscape:
                              md:w-width-4.1-md md:h-4
                              md-landscape:
                              lg:
                              lg-landscape:w-icon-star-width lg-landscape:h-icon-star
                              xl-landscape:" src="../../../../assets/icons/star.svg" alt="">
                <p class="relative font-PoppinsLight text-black
                                        us:text-sm us:ml-1
                                        us-landscape:
                                        md:text-sm md:ml-1
                                        md-landscape:
                                        lg:
                                        lg-landscape:text-xs lg-landscape:ml-2
                                        xl-landscape:text-sm">{{item?.rating}}</p> -->
              </div>
            </div>
            <div class="flex items-center
                      us:
                      us-landscape:
                      md:
                      md-landscape:
                      lg:
                      lg-landscape:ml-margin-icon-card
                      xl-landscape:">
              <!-- <img class="relative
                          us:w-4 us:h-height-4.5-md
                          us-landscape:
                          md:w-4 md:h-height-4.5-md
                          md-landscape:
                          lg:
                          lg-landscape:w-icon-cards-width lg-landscape:h-icon-first-card
                          xl-landscape:" src="../../../../assets/icons/share.svg" alt="">
              <img class="relative
                          us:w-5 us:h-height-4.5-md us:ml-5
                          us-landscape:
                          md:w-5 md:h-height-4.5-md md:ml-5
                          md-landscape:
                          lg:
                          lg-landscape:w-icon-heart-width lg-landscape:h-icon-first-card lg-landscape:ml-margin-icon-heart
                          xl-landscape:" src="../../../../assets/icons/filled-heart.svg" alt=""> -->
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="mexican_hits.length == 0" class="relative w-full h-auto grid
      us:grid-cols-1 us:gap-y-3
      us-landscape:
      md:grid-cols-2 md:gap-x-gap-6.5 md:gap-y-3
      md-landscape:
      lg:
      lg-landscape:grid-cols-5 lg-landscape:gap-x-2 lg-landscape:gap-y-3
      xl-landscape:gap-y-4 xl-landscape:gap-x-3">
        <div *ngFor="let item of skeletons_grids" class="card relative w-auto bg-white cursor-pointer
         us:h-height-85-md us:pt-2 us:pl-2 us:pr-2 us:pb-4 us:rounded-xl
         us-landscape:
         md:h-height-85-md md:pt-2 md:pl-2 md:pr-2 md:pb-4 md:rounded-xl
         md-landscape:
         lg:
         lg-landscape:h-full-hd-card-height lg-landscape:p-padding-2 lg-landscape:rounded-xl
         xl-landscape:rounded-2xl">
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'border-radius': '10px',
            'height': '55%',
            'width':'100%'
            }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'100%'
            }"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'50%'
            }"></ngx-skeleton-loader>
          <div class="relative w-full">
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'30%'
            }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
            'width':'30%',
            'margin-left':'3%'
            }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" animation="progress" appearance="line" [theme]="{
          'width':'30%',
          'margin-left':'3%'
          }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <div class="fadeIn pagination relative h-auto w-full flex justify-center items-center
                  us:
                  us-landscape:
                  md:
                  md-landscape:
                  lg:
                  lg-landscape:mt-margin-top-line-card
                  xl-landscape:">
      <button class="relative bg-gray-slider-body-back-color flex justify-center items-center
                         us:
                         us-landscape:
                         md:
                         md-landscape:
                         lg:
                         lg-landscape:w-width-11 lg-landscape:h-height-11 lg-landscape:rounded-lg
                         xl-landscape:rounded-xl">
          <img class="relative
                          us:
                          us-landscape:
                          md:
                          md-landscape:
                          lg:
                          lg-landscape:w-width-2 lg-landscape:h-height-3
                          xl-landscape:" src="../../../../assets/icons/slider-left.svg" alt="">
      </button>
      <button class="relative bg-red-primary text-white flex justify-center items-center font-PoppinsBold
                         us:
                         us-landscape:
                         md:
                         md-landscape:
                         lg:
                         lg-landscape:w-width-11 lg-landscape:h-height-11 lg-landscape:rounded-lg lg-landscape:text-text-base lg-landscape:ml-margin-3 lg-landscape:mr-margin-3
                         xl-landscape:rounded-xl">1
      </button>
      <button class="relative bg-gray-slider-body-back-color flex justify-center items-center
                         us:
                         us-landscape:
                         md:
                         md-landscape:
                         lg:
                         lg-landscape:w-width-11 lg-landscape:h-height-11 lg-landscape:rounded-lg
                         xl-landscape:xl-landscape:rounded-xl">
          <img class="relative
                         us:
                         us-landscape:
                         md:
                         md-landscape:
                         lg:
                         lg-landscape:w-width-2 lg-landscape:h-height-3
                         xl-landscape:" src="../../../../assets/icons/slider-right.svg" alt="">
      </button>
      <button class="relative bg-gray-slider-body-back-color flex justify-center items-center font-PoppinsBold text-gray-border-bottom-gray
                         us:
                         us-landscape:
                         md:
                         md-landscape:
                         lg:
                         lg-landscape:w-width-11 lg-landscape:h-height-11 lg-landscape:rounded-lg lg-landscape:text-text-base lg-landscape:ml-margin-3
                         xl-landscape:rounded-xl">...
      </button>
  </div> -->
</div>