import { AfterViewInit, Component, Inject, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as L from 'leaflet';
import { TrucksService } from 'src/app/trucks/services/trucks.service';
import { MapsAPILoader } from '@agm/core';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {
  // @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  map: any;
  options: any = {
    componentRestrictions: { country: ['US'] }
  };
  marker: any;
  lat: any = 0;
  lng: any = 0;
  current_address: any = '';
  geoCoder: any;
  @ViewChild('map_google_ref') mapSearchElementRef: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<MapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public truckService: TrucksService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('address')) {
      this.current_address = sessionStorage.getItem('address');
    }
  }

  ngAfterViewInit(): void {
    this.loadMap();
    this.mapsAPILoader.load().then(() => {
    if (google.maps.places && google) {
      this.geoCoder = new google.maps.Geocoder;
      this.geoCoder.componentRestrictions = {
        country: ['US']
      }
      let map_autocomplete = new google.maps.places.Autocomplete(this.mapSearchElementRef.nativeElement, this.options);
      map_autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = map_autocomplete.getPlace();
          this.handleAddressChange(place);
        });
      });
    }
    });
  }

  loadMap(): void {
    this.map = L.map('map').setView([this.data?.lat, this.data.lng], 16);
    // L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
    //   // attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    //   maxZoom: 20,
    //   id: 'mapbox/streets-v11',
    //   tileSize: 512,
    //   zoomOffset: -1,
    //   accessToken: 'pk.eyJ1IjoiYWN1Y3RlYyIsImEiOiJja2xkYWlzOWgwZ28zMnhwN2h6MTExY2FwIn0.xA8q4gZl2qVnbXdneSFRig',
    // }).addTo(this.map);
    L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }).addTo(this.map);

    const icon = L.icon({
      iconUrl: '../../../assets/icons/map/marker-icon.png',
      iconSize: [25, 41],
      iconAnchor: [12.5, 41],
      // shadowUrl: 'assets/images/marker-shadow.png',
      popupAnchor: [0, -41],
    });
    this.marker = L.marker([this.data?.lat, this.data?.lng], { icon }).bindPopup('Angular Leaflet');
    this.marker.addTo(this.map);

    // this.map.on('zoomend', () => {
    //   this.enableMap();
    // });
  }

  closeWithData() {
    this.dialogRef.close({
      lat: this.lat,
      lng: this.lng
    });
    sessionStorage.setItem('address', this.current_address);
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.current_address = results[0].formatted_address;
          this.closeWithData();
          // console.log(results[0].formatted_address);
        } else {
          this.closeWithData();
          this.current_address = "Unknown Address";
          console.log('No results found');
        }
      } else {
        this.closeWithData();
        this.current_address = "Unknown Address";
        console.log('Geocoder failed due to: ' + status);
      }

    });
  }

  close() {
    this.dialogRef.close({
      lat: 0,
      lng: 0
    });
  }

  public handleAddressChange(address: any) {

    this.lat = address?.geometry?.location?.lat();
    this.lng = address?.geometry?.location?.lng();
    this.current_address = address?.formatted_address;
    this.truckService.isInArr(address?.formatted_address) ? null : this.truckService.previous_directions.unshift(address);

    const icon = L.icon({
      iconUrl: '../../../assets/icons/map/marker-icon.png',
      iconSize: [25, 41],
      iconAnchor: [12.5, 41],
      // shadowUrl: 'assets/images/marker-shadow.png',
      popupAnchor: [0, -41],
    });

    this.map.removeLayer(this.marker)
    // this.map.flyTo([this.lat, this.lng], 16);
    this.map.setView([this.lat, this.lng], 16);
    this.marker = L.marker([this.lat, this.lng], { icon }).bindPopup('Angular Leaflet');
    this.marker.addTo(this.map);
    // this.disableMap();

    this.closeWithData();
  }

  disableMap() {
    this.map.dragging.disable();
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
    if (this.map.tap) this.map.tap.disable();
  }

  enableMap() {
    this.map.dragging.enable();
    this.map.touchZoom.enable();
    this.map.doubleClickZoom.enable();
    this.map.scrollWheelZoom.enable();
    this.map.boxZoom.enable();
    this.map.keyboard.enable();
    if (this.map.tap) this.map.tap.enable();
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(res => {
        this.lat = res.coords.latitude;
        this.lng = res.coords.longitude;
        this.getAddress(this.lat, this.lng)
      });
    }
  }

}
