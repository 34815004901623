import { NgModule } from '@angular/core';
import { BrowserModule, } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { AppComponent } from './app.component';
import { MenuComponent } from './base-components/menu/menu.component';
import { FooterComponent } from './base-components/footer/footer.component';
import { MainComponent } from './base-components/main/main.component';
import { SearchComponent } from './shared/components/search/search.component';

import { HomeComponent } from './shared/components/home/home.component';
import { SharedModule } from './shared/modules/shared/shared.module';
import { MapComponent } from './shared/components/map/map.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { ModalMapComponent } from './shared/components/modal-map/modal-map.component';
import { AgmCoreModule } from '@agm/core';
import { OwnersComponent } from './base-components/owners/owners.component';
import { AllGridComponent } from './shared/components/all-grid/all-grid.component';





@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    MainComponent,
    SearchComponent,
    HomeComponent,
    MapComponent,
    NotificationComponent,
    ModalMapComponent,
    OwnersComponent,
    AllGridComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatAutocompleteModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFireAuthModule,
    MatExpansionModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAY5iw3Q40vnTBmlXBfl6lqdT6Iocxz7zc',
      libraries: ['places']
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    ScullyLibModule,
  ],
  providers: [
    // { provide: GsToHttps },
    // { provide: GetCuisineGeneralType },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase }
  ],
  exports: [
    AgmCoreModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
