import { Pipe, PipeTransform } from '@angular/core';
import { getDownloadURL, ref } from '@angular/fire/storage';
import { AlgoliaFireService } from '../services/algolia.service';

@Pipe({
    name: 'twoDecimalsMetersToMiles'
})

export class TwoDecimalsMetersToMiles implements PipeTransform {

    constructor() {

    }

    transform(num: any) {
        let result = 0;
        // result = parseFloat((num / 1609).toFixed(2));
        result = Math.round(num / 1609);
        return result;
    }
}