import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

  toHome(){

    window.scrollTo(0, 0)
  }
  toServices(){
    //window.scrollTo(0, 940)
    document.getElementById("services")?.scrollIntoView()
  }
  toAbout(){
    document.getElementById("about")?.scrollIntoView()
  }
  toContact(){
    //window.scrollTo(0, 7000)
    document.getElementById("contactUs")?.scrollIntoView()
  }

}
