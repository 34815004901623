<section
    class="relative
    us:
    us-landscape:
    md:w-65vw md:h-41vw md:p-padding-4-md
    md-landscape:
    lg:
    lg-landscape:w-46vw lg-landscape:h-24vw lg-landscape:pl-2vw lg-landscape:pr-2vw lg-landscape:pb-2vw lg-landscape:pt-1.5vw
    xl-landscape:">
    <img (click)="close()" class="absolute cursor-pointer
    us:
    us-landscape:
    md:top-1.5vw md:right-2vw md:rounded-md
    md-landscape:
    lg:
    lg-landscape:top-1.5vw lg-landscape:right-2vw lg-landscape:rounded-lg
    xl-landscape:" src="../../../../assets/imgs/close.svg"
        alt="">
    <p class="relative font-PoppinsBold text-gray-333333 w-full text-center
    us:
    us-landscape:
    md:text-font-20-md md:mt-margin-11-md
    md-landscape:
    lg:
    lg-landscape:text-font-24 lg-landscape:mt-2.5vw
    xl-landscape:">
        <span *ngIf="this.type=='Error'">
            Upsss!
        </span>
        <span *ngIf="this.type=='Success'">
            Congratulations!!!
        </span>
    </p>
    <div class="relative w-full flex justify-center items-center h-auto">
        <img *ngIf="this.type=='Error'" class="relative
        us:
        us-landscape:
        md:w-width-60-md md:h-height-60-md md:mt-margin-2-md
        md-landscape:
        lg:
        lg-landscape:w-9.3vw lg-landscape:h-9.3vw lg-landscape:mt-0.8vw
        xl-landscape:" src="../../../../assets/imgs/notify-warn.jpg"
            alt="">
        <img *ngIf="this.type=='Success'" class="relative
        us:
        us-landscape:
        md:w-width-60-md md:h-height-60-md md:mt-margin-2-md
        md-landscape:
        lg:
        lg-landscape:w-9.3vw lg-landscape:h-9.3vw lg-landscape:mt-0.8vw
        xl-landscape:"
            src="../../../../assets/imgs/notify-success.jpg" alt="">
    </div>
    <p class="relative font-PoppinsBold w-full text-center text-gray-777777 uppercase
    us:
    us-landscape:
    md:text-font-12-md md:mt-margin-3-md
    md-landscape:
    lg:
    lg-landscape:text-font-14 lg-landscape:mt-1.25vw
    xl-landscape:">
        {{msg}}
    </p>

    <button (click)="close()" class="relative w-full bg-red-primary text-white font-PoppinsBold border-none outline-none ring-0 rounded-lg
    us:
    us-landscape:
    md:h-height-10-md md:text-font-16-md mt-margin-4-md
    md-landscape:
    lg:
    lg-landscape:h-2.6vw lg-landscape:text-font-18 lg-landscape:mt-1.6vw
    xl-landscape:">{{btn_text}}</button>
</section>
