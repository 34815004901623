<div id="home" class="relative flex flex-col justify-center items-center w-full h-auto bg-gray-50
            us:
            us-landscape:
            md:
            md-landscape:
            lg:mt-20 lg:pt-10 lg:px-10
            lg-landscape:mt-100px lg-landscape:pt-14 lg-landscape:px-20
            xl-landscape:pb-12 xl-landscape:pl-202px xl-landscape:pr-202px ">

  <div class="relative flex flex-col justify-center items-center
            lg-landscape:w-auto lg-landscape:h-auto">
    <h1 class="relative text-black font-bold font-PoppinsMedium
    lg:text-font-20-md lg:mb-2 lg:-ml-64
    lg-landscape:text-font-32 lg-landscape:mb-margin-4 lg-landscape:-ml-335px">Find the perfect food truck for your
      next event</h1>

    <div class="relative flex h-auto
                us:
                us-landscape:
                md:
                md-landscape:
                lg:gap-x-4
                lg-landscape:gap-x-gap-6
                xl-landscape:">

      <div class="relative h-full flex items-center
                                us:
                                us-landscape:
                                md:
                                md-landscape:
                                lg:w-89.2
                                lg-landscape:w-575px
                                xl-landscape:">
        <span class="absolute z-10 left-2 icon-pin-drop-round-24px-2 text-red-primary
                                   us:
                                   us-landscape:
                                   md:
                                   md-landscape:
                                   lg:text-2xl
                                   lg-landscape:text-2xl
                                   xl-landscape:text-3xl"></span>
        <input type="text" #google_ref placeholder="Enter address to find food trucks" class="relative font-PoppinsMedium font-bold text-black border-none focus:border-none focus:outline-none focus:ring-0 bg-white
                                   us:
                                   us-landscape:
                                   md:
                                   md-landscape:
                                   lg:h-12 lg:rounded-md lg:text-sm lg:w-full lg:pl-9 lg:pr-9
                                   lg-landscape:h-70px lg-landscape:rounded-lg lg-landscape:text-font-14 lg-landscape:w-full lg-landscape:pl-9 lg-landscape:pr-9
                                   xl-landscape:lg-landscape:pl-11 xl-landscape:pr-11">

      </div>
      <div class="relative flex justify-start items-center bg-white
                                us:
                                us-landscape:
                                md:
                                md-landscape:
                                lg:w-auto lg:h-12 lg:rounded-md lg:pl-2 lg:pr-2
                                lg-landscape:w-auto lg-landscape:h-70px lg-landscape:rounded-lg lg-landscape:pl-padding-4 lg-landscape:pr-padding-4
                                xl-landscape:">

        <p (click)="getCurrentLocation()" class="relative cursor-pointer font-PoppinsMedium font-medium text-black
                            us:
                            us-landscape:
                            md:
                            md-landscape:
                            lg:text-sm lg:mr-2
                            lg-landscape:text-font-14 lg-landscape:mr-4
                            xl-landscape:">Current location</p>

        <img class="relative
                            us:
                            us-landscape:
                            md:
                            md-landscape:
                            lg:w-3.5 lg:h-4
                            lg-landscape:w-4 lg-landscape:h-4.5
                            xl-landscape:" src="../../../../assets/icons/location-br.svg" alt="food truck location">
      </div>


      <button
        class="relative bg-red-primary flex items-center uppercase text-white font-PoppinsMedium font-bold tracking-widest
        lg:w-auto lg:h-12 lg:rounded-md lg:px-2 lg:text-sm
        lg-landscape:w-auto lg-landscape:h-70px lg-landscape:rounded-lg lg-landscape:px-padding-5 lg-landscape:text-font-14">lets
        go</button>

    </div>
    <div class="relative flex flex-col justify-center items-center lg-landscape:w-auto lg-landscape:h-auto">
      <p class="relative pb-padding-4 pt-padding-12 mt-margin-12 text-black font-bold font-PoppinsMedium
  lg:text-font-20-md lg:mb-2
  lg-landscape:text-font-32 lg-landscape:mb-margin-4">Looking for any Savory or Dessert?</p>
    </div>

    <button class="relative bg-red-primary flex items-center uppercase text-white font-PoppinsMedium font-bold tracking-widest
  lg:w-auto lg:h-12 lg:rounded-md lg:px-2 lg:text-sm
  lg-landscape:w-auto lg-landscape:h-70px lg-landscape:rounded-lg lg-landscape:px-padding-5 lg-landscape:text-font-14"
      (click)="redirectToAppStore()">SEND REQUEST TO ALL FOODTRUCKS</button>

    <section class="relative">
      <div class="relative flex
      us:
      us-landscape:
      md:
      md-landscape:
      lg:pb-10 lg:gap-x-7 lg:mt-8
      lg-landscape:pb-padding-12 lg-landscape:gap-x-20 lg-landscape:mt-14
      ">
        <div class="relative flex flex-col w-auto h-auto
        lg:pt-10
        lg-landscape:pt-10 ">

          <div class="relative w-auto h-auto
          lg:pl-8 lg:pr-5
          lg-landscape:px-7">

            <div class="absolute rounded-full overflow-hidden transform rotate-135
        us:
        us-landscape:
        md:
        md-landscape:
        lg:h-8 lg:w-8 lg:left-6
        lg-landscape:h-height-12 lg-landscape:w-width-12 lg-landscape:left-9
        xl-landscape:left-8">
              <div class="relative h-50%"></div>
              <div class="relative h-50% bg-gray-333333"></div>
            </div>

            <!-- <div class="relative
        us:
        us-landscape:
        md:
        md-landscape:
        lg:mt-5
        lg-landscape:mt-margin-9
        xl-landscape:mt-9">
              <p class="relative text-gray-333333 font-PoppinsBold font-bold
          us:
          us-landscape:
          md:
          md-landscape:
          lg:text-6xl lg:leading-tight
          lg-landscape:text-font-96
          xl-landscape:">Go with your</p>
              <p class="relative text-red-primary font-PoppinsBold font-bold
          us:
          us-landscape:
          md:
          md-landscape:
          lg:text-6xl lg:leading-tight
          lg-landscape:text-font-96
          xl-landscape:">gut... trucks!</p>
            </div> -->

            <div class="absolute z-10 rounded-full overflow-hidden transform rotate-135
        us:
        us-landscape:
        md:
        md-landscape:
        lg:h-8 lg:w-8 lg:left-388px lg:top-163px
        lg-landscape:h-height-12 lg-landscape:w-width-12 lg-landscape:left-145 lg-landscape:top-250px
        xl-landscape:left-144 xl-landscape:top-67.5">
              <div class="relative h-50% bg-red-primary"></div>
              <div class="relative h-50%"></div>
            </div>

          </div>


          <div class="relative flex
          us:
          us-landscape:
          md:
          md-landscape:
          lg:mt-14
          lg-landscape:mt-88px
          xl-landscape:">
            <img class="
            us:
            us-landscape:
            md:
            md-landscape:
            lg:h-auto lg:w-24
            lg-landscape:w-128px lg-landscape:h-144px
            xl-landscape:" src="../../../../assets/imgs/home/Group 1293.png" alt="food truck booking app">

            <div class="relative flex flex-col
            us:
            us-landscape:
            md:
            md-landscape:
            lg:ml-8 lg:w-auto lg:pb-3 lg:pr-4
            lg-landscape:ml-70px lg-landscape:w-width-121 lg-landscape:pb-padding-3 lg-landscape:pr-padding-5
            xl-landscape:">
              <p class="relative text-left font-PoppinsBold font-bold
              us:
              us-landscape:
              md:
              md-landscape:
              lg:text-lg
              lg-landscape:text-font-24 lg-landscape:leading-leading-36
              xl-landscape:">Download GutTrucks , your food truck finder and catering app </p>

              <div class="relative flex
              us:
              us-landscape:
              md:
              md-landscape:
              lg:mt-5
              lg-landscape:mt-6
              xl-landscape:">
                <a [href]="appLinkPlayStore" target="_blank" class="relative
              us:
              us-landscape:
              md:
              md-landscape:
              lg:w-36
              lg-landscape:w-width-40 lg-landscape:h-height-12
              xl-landscape:">
                  <img src="../../../../assets/imgs/home/g4036.jpg" alt="food truck app">
                </a>
                <a [href]="appLinkAppStore" target="_blank" class="relative
              us:
              us-landscape:
              md:
              md-landscape:
              lg:w-32 lg:ml-5
              lg-landscape:w-width-36 lg-landscape:h-height-12 lg-landscape:ml-margin-9
              xl-landscape:">
                  <img src="../../../../assets/imgs/home/Group (1).jpg" alt="food truck app">
                </a>
              </div>
            </div>
          </div>

        </div>

        <div class="relative rounded-2xl
        lg:h-auto lg:w-auto
        lg-landscape:h-auto lg-landscape:w-auto">

          <!-- <div class="relative flex
          lg:w-411px lg:h-auto
          lg-landscape:w-592px lg-landscape:h-auto">

            <div class="relative
            lg:w-1/2 lg:h-full
            lg-landscape:w-1/2 lg-landscape:h-full
            xl-landscape:w-auto xl-landscape:h-full">
              <img src="../../../../assets/imgs/home/woman-2592247_1920.png" alt="">
            </div>

            <div
              class="relative bg-red-primary rounded-r-2xl
            lg:pr-4 lg:pl-4 lg:pt-4 lg:pb-1 lg:w-1/2
            lg-landscape:pr-padding-6 lg-landscape:pl-padding-5 lg-landscape:pt-padding-5 lg-landscape:pb-2 lg-landscape:w-1/2">
              <p class="relative text-white font-PoppinsLight font-extralight uppercase
              lg:text-lg lg:mb-1.5
                  lg-landscape:text-font-24 lg-landscape:mb-2.5">don´t stop</p>

              <p class="relative text-white font-PoppinsBold font-bold
              lg:text-sm lg:mb-2
              lg-landscape:text-font-16 lg-landscape:mb-6
              xl-landscape:mb-11">Book any savory or dessert</p>

              <a class="relative w-auto h-auto"
                href="https://play.google.com/store/apps/details?id=com.guttrucks.client">
                <button class="relative bg-white rounded-full w-full text-red-primary font-PoppinsBold font-bold
                lg:h-6 lg:text-sm
                lg-landscape:h-height-10 lg-landscape:text-font-16">Book now</button>
              </a>
            </div>
          </div> -->

          <!-- <div class="relative
          lg-landscape:mt-5
          xl-landscape:mt-9">
            <img class="relative z-20 h-auto
            lg-landscape:w-592px lg-landscape:ml-5
            xl-landscape:w-592px xl-landscape:ml-0" src="../../../../assets/imgs/home/vanCOLORS 1.png" alt="">
            <div class="absolute bg-red-primary rounded-b-full
                lg:w-48 lg:h-24 lg:bottom-3
                lg-landscape:w-295px lg-landscape:h-147px lg-landscape:bottom-3
                xl-landscape:w-257px xl-landscape:h-136px xl-landscape:bottom-6px">
            </div>
          </div> -->
        </div>

      </div>
      <!-- <div class="relative flex justify-center items-center gap-x-6 mb-11">
        <div class="relative w-width-3.7 h-height-3.7 rounded-full bg-red-primary"></div>
        <div class="relative w-width-3.7 h-height-3.7 rounded-full bg-white border-red-primary border-2"></div>
        <div class="relative w-width-3.7 h-height-3.7 rounded-full bg-white border-red-primary border-2"></div>
        <div class="relative w-width-3.7 h-height-3.7 rounded-full bg-white border-red-primary border-2"></div>
      </div> -->
    </section>
  </div>



</div>


<section class="relative flex flex-col items-center">
  <div class="relative " id="services">
    <img class="relative" src="../../../../assets/imgs/home/Group 1267.jpg" alt="easy booking food truck">
  </div>
  <div class="relative">
    <img class="relative" src="../../../../assets/imgs/home/Group 1269.jpg" alt="find a food truck near me">
  </div>
  <div class="relative">
    <img class="relative" src="../../../../assets/imgs/home/Group 1266.jpg" alt="food truck owner">
  </div>
  <div class="relative" id="about">
    <img class="relative" src="../../../../assets/imgs/home/Group 1290.jpg"
      alt="guttrucks app interacts directly with food trucks">
  </div>
  <div id="download" class="relative bg-dan-gold bg-cover w-full flex flex-col justify-center items-center
  us:
  us-landscape:
  md:h-height-73-md
  md-landscape:
  lg:
  lg-landscape:h-height-136.5
  xl-landscape:">
    <h2 class="relative text-gray-333333 font-PoppinsBold
    us:
    us-landscape:
    md:text-font-24-md md:leading-leading-32-md
    md-landscape:
    lg:
    lg-landscape:text-font-28 lg-landscape:leading-leading-28
    xl-landscape:">Download and discover the best food truck catering services</h2>
    <div class="relative flex
    us:
    us-landscape:
    md:mt-margin-5-md
    md-landscape:
    lg:
    lg-landscape:mt-margin-7
    xl-landscape:">
      <a href="https://play.google.com/store/apps/details?id=com.guttrucks.client" target="_blank" class="relative
      us:
      us-landscape:
      md:w-width-36-md md:h-height-10-md
      md-landscape:
      lg:
      lg-landscape:w-width-40 lg-landscape:h-height-12
      xl-landscape:">
        <img class="rounded-md overflow-hidden" src="../../../../assets/imgs/home/g4036.jpg" alt="food truck app">
      </a>
      <a href="https://apps.apple.com/us/app/guttrucks/1602980633" target="_blank" class="relative
      us:
      us-landscape:
      md:w-width-32-md md:h-height-10-md md:ml-margin-9-md
      md-landscape:
      lg:
      lg-landscape:w-width-36 lg-landscape:h-height-12 lg-landscape:ml-margin-9
      xl-landscape:">
        <img class="rounded-lg overflow-hidden" src="../../../../assets/imgs/home/Group (1).jpg" alt="food truck app">
      </a>
    </div>
  </div>
</section>

<section id="questions" class="relative bg-questions bg-contain
us:
us-landscape:
md:h-height-165.7-md
md-landscape:
lg:
lg-landscape:h-height-310.2
xl-landscape:">
  <div class="relative flex justify-center
  us:
  us-landscape:
  md:pt-padding-14-md
  md-landscape:
  lg:
  lg-landscape:pt-padding-28-lg
  xl-landscape:">
    <div class="absolute rounded-full overflow-hidden transform rotate-135
    us:
    us-landscape:
    md:h-height-6-md md:w-width-6-md md:mb-margin-3-md md:top-11-md md:left-58-md
    md-landscape:
    lg:
    lg-landscape:top-25 lg-landscape:left-115 lg-landscape:h-height-8 lg-landscape:w-width-8 lg-landscape:mb-margin-10
    xl-landscape:">
      <div class="relative h-50%"></div>
      <div class="relative h-50% bg-red-primary"></div>
    </div>

    <p class="relative text-gray-333333 font-PoppinsBold font-bold
    us:
    us-landscape:
    md:text-font-36-md md:leading-leading-40-md
    md-landscape:
    lg:
    lg-landscape:text-font-64 lg-landscape:leading-leading-64
    xl-landscape:">Frequently Asked
      Questions</p>

    <div class="absolute rounded-full overflow-hidden transform rotate-135
    us:
    us-landscape:
    md:w-width-6-md md:h-height-6-md md:top-20-md md:left-192.5-md
    md-landscape:
    lg:
    lg-landscape:left-352 lg-landscape:top-40 lg-landscape:h-height-8 lg-landscape:w-width-8
    xl-landscape:">
      <div class="relative h-50% bg-red-primary"></div>
      <div class="relative h-50%"></div>
    </div>
  </div>

  <mat-accordion class="flex flex-col justify-center items-center
  us:
  us-landscape:
  md:mt-margin-10-md md:gap-y-gap-2-md
  md-landscape:
  lg:
  lg-landscape:mt-margin-17 lg-landscape:gap-y-gap-4
  xl-landscape:">
    <mat-expansion-panel class="relative
    us:
    us-landscape:
    md:w-width-162.5-md md:rounded-xl
    md-landscape:
    lg:
    lg-landscape:w-width-302 lg-landscape:rounded-2xl
    xl-landscape:">
      <mat-expansion-panel-header class="
      us:
      us-landscape:
      md:h-height-12-md
      md-landscape:
      lg:
      lg-landscape:h-height-18
      xl-landscape:">
        <p class="relative
        us:
        us-landscape:
        md:text-font-18-md md:leading-leading-28-md
        md-landscape:
        lg:
        lg-landscape:text-font-28 lg-landscape:leading-leading-28
        xl-landscape:">What does the GutTrucks app offer?</p>
      </mat-expansion-panel-header>
      <div class="relative h-1px bg-gray-E0E0E0
      us:
      us-landscape:
      md:w-width-150-md md:mb-margin-4-md
      md-landscape:
      lg:
      lg-landscape:w-width-287 lg-landscape:mb-margin-6
      xl-landscape:"></div>
      <p class="font-PoppinsLight font-light
      us:
      us-landscape:
      md:text-font-14-md md:
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-30
      xl-landscape:"><a class="font-PoppinsBold">The GutTrucks app provides a platform for finding and booking food
          trucks for your events, ordering food online, and viewing food truck menus. Additionally, the app enables you
          to send requests to multiple food trucks and communicate with them directly.</a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel class="relative
      us:
      us-landscape:
      md:w-width-162.5-md md:rounded-xl
      md-landscape:
      lg:
      lg-landscape:w-width-302 lg-landscape:rounded-2xl
      xl-landscape:">
      <mat-expansion-panel-header class="
      us:
      us-landscape:
      md:h-height-12-md
      md-landscape:
      lg:
      lg-landscape:h-height-18
      xl-landscape:">
        <p class="relative font-PoppinsRegular font-normal
        us:
        us-landscape:
        md:text-font-18-md md:
        md-landscape:
        lg:
        lg-landscape:text-font-28 lg-landscape:leading-leading-28
        xl-landscape:">What is a public event?</p>
      </mat-expansion-panel-header>
      <p class="font-PoppinsLight font-light
      us:
      us-landscape:
      md:text-font-14-md md:
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-30
      xl-landscape:"><a class="font-PoppinsBold">A public event is where food trucks show up and sell their food to the
          public, who can find and order from the truck using the GutTrucks app. A private event, on the other hand, is
          where the host pays for the food truck to come and serve food to guests or employees, without the option for
          the public to purchase food directly from the truck.</a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel class="relative
      us:
      us-landscape:
      md:w-width-162.5-md md:rounded-xl
      md-landscape:
      lg:
      lg-landscape:w-width-302 lg-landscape:rounded-2xl
      xl-landscape:">
      <mat-expansion-panel-header class="
      us:
      us-landscape:
      md:h-height-12-md
      md-landscape:
      lg:
      lg-landscape:h-height-18
      xl-landscape:">
        <p class="relative font-PoppinsRegular font-normal
        us:
        us-landscape:
        md:text-font-18-md md:
        md-landscape:
        lg:
        lg-landscape:text-font-28 lg-landscape:leading-leading-28
        xl-landscape:">What is a private event?</p>
      </mat-expansion-panel-header>
      <p class="font-PoppinsLight font-light
      us:
      us-landscape:
      md:text-font-14-md md:
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-30
      xl-landscape:"><a class="font-PoppinsBold">Food truck catering refers to hiring a food truck for a private event,
          where the host pays for the food truck's services. It's similar to other types of catering services.</a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel class="relative
    us:
    us-landscape:
    md:w-width-162.5-md md:rounded-xl
    md-landscape:
    lg:
    lg-landscape:w-width-302 lg-landscape:rounded-2xl
    xl-landscape:">
      <mat-expansion-panel-header class="
      us:
      us-landscape:
      md:h-height-12-md
      md-landscape:
      lg:
      lg-landscape:h-height-18
      xl-landscape:">
        <p class="relative font-PoppinsRegular font-normal
        us:
        us-landscape:
        md:text-font-18-md md:
        md-landscape:
        lg:
        lg-landscape:text-font-28 lg-landscape:leading-leading-28
        xl-landscape:">What do I need to provide if
          a food truck is coming to my event?</p>
      </mat-expansion-panel-header>
      <p class="font-PoppinsLight font-light
      us:
      us-landscape:
      md:text-font-14-md md:
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-30
      xl-landscape:"><a class="font-PoppinsBold">Food trucks come with everything they need to cook and serve food on
          the go. All they require is access to a restroom for their staff during the event. For events longer than a
          few hours, it's helpful to provide power but it's not necessary. If your event runs overnight, power is
          strongly recommended. The food truck will bring disposable serving wares and a trash can, but it's a good idea
          to have extra trash cans available. If you want a place for your guests to sit and eat, you'll need to provide
          tables and chairs. Otherwise, the food truck will arrive, serve your guests delicious food, and then clean up
          and drive away, leaving your event location clean and tidy.</a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel class="relative
    us:
    us-landscape:
    md:w-width-162.5-md md:rounded-xl
    md-landscape:
    lg:
    lg-landscape:w-width-302 lg-landscape:rounded-2xl
    xl-landscape:">
      <mat-expansion-panel-header class="
      us:
      us-landscape:
      md:h-height-12-md
      md-landscape:
      lg:
      lg-landscape:h-height-18
      xl-landscape:">
        <p class="relative font-PoppinsRegular font-normal
        us:
        us-landscape:
        md:text-font-18-md md:
        md-landscape:
        lg:
        lg-landscape:text-font-28 lg-landscape:leading-leading-28
        xl-landscape:">What type of cuisine options are available through food trucks?</p>
      </mat-expansion-panel-header>
      <p class="font-PoppinsLight font-light
      us:
      us-landscape:
      md:text-font-14-md md:
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-30
      xl-landscape:"><a class="font-PoppinsBold">Our food truck options include a diverse range of cuisines to cater to
          a variety of taste preferences.</a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel class="relative
    us:
    us-landscape:
    md:w-width-162.5-md md:rounded-xl
    md-landscape:
    lg:
    lg-landscape:w-width-302 lg-landscape:rounded-2xl
    xl-landscape:">
      <mat-expansion-panel-header class="
      us:
      us-landscape:
      md:h-height-12-md
      md-landscape:
      lg:
      lg-landscape:h-height-18
      xl-landscape:">
        <p class="relative font-PoppinsRegular font-normal
        us:
        us-landscape:
        md:text-font-18-md md:
        md-landscape:
        lg:
        lg-landscape:text-font-28 lg-landscape:leading-leading-28
        xl-landscape:">What types of events can be catered by food trucks?</p>
      </mat-expansion-panel-header>
      <p class="font-PoppinsLight font-light
      us:
      us-landscape:
      md:text-font-14-md md:
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-30
      xl-landscape:"><a class="font-PoppinsBold">The food trucks we offer can cater to a wide range of events and
          occasions, including corporate gatherings, private parties, festivals, and more, ensuring that your needs and
          expectations are met.</a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel class="relative
    us:
    us-landscape:
    md:w-width-162.5-md md:rounded-xl
    md-landscape:
    lg:
    lg-landscape:w-width-302 lg-landscape:rounded-2xl
    xl-landscape:">
      <mat-expansion-panel-header class="
      us:
      us-landscape:
      md:h-height-12-md
      md-landscape:
      lg:
      lg-landscape:h-height-18
      xl-landscape:">
        <p class="relative font-PoppinsRegular font-normal
        us:
        us-landscape:
        md:text-font-18-md md:
        md-landscape:
        lg:
        lg-landscape:text-font-28 lg-landscape:leading-leading-28
        xl-landscape:">What if I need to cancel or
          reschedule my event?
        </p>
      </mat-expansion-panel-header>
      <p class="font-PoppinsLight font-light
      us:
      us-landscape:
      md:text-font-14-md md:
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-30
      xl-landscape:"><a class="font-PoppinsBold">If you need to change the date or time of your event, we'll try our
          best to work with the food truck you booked. If it's not possible, the cancellation policy in the catering
          agreement will apply.</a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel class="relative
    us:
    us-landscape:
    md:w-width-162.5-md md:rounded-xl
    md-landscape:
    lg:
    lg-landscape:w-width-302 lg-landscape:rounded-2xl
    xl-landscape:">
      <mat-expansion-panel-header class="
      us:
      us-landscape:
      md:h-height-12-md
      md-landscape:
      lg:
      lg-landscape:h-height-18
      xl-landscape:">
        <p class="relative font-PoppinsRegular font-normal
        us:
        us-landscape:
        md:text-font-18-md md:
        md-landscape:
        lg:
        lg-landscape:text-font-28 lg-landscape:leading-leading-28
        xl-landscape:">What if I want more than one
          food truck at my event?
        </p>
      </mat-expansion-panel-header>
      <p class="font-PoppinsLight font-light
      us:
      us-landscape:
      md:text-font-14-md md:
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-30
      xl-landscape:"><a class="font-PoppinsBold">The number of guests a food truck can serve per hour is usually
          between 60 to 100. The number of trucks needed for your event depends on the number of guests, the time of the
          event, and the menu you have chosen. If you need help figuring out the right number of trucks for your event,
          you can contact our team at support@guttrucks.com or consider booking multiple trucks. Just make sure to
          budget enough to meet each truck's minimum requirements.</a>
      </p>
    </mat-expansion-panel>

    <mat-expansion-panel class="relative
    us:
    us-landscape:
    md:w-width-162.5-md md:rounded-xl
    md-landscape:
    lg:
    lg-landscape:w-width-302 lg-landscape:rounded-2xl
    xl-landscape:">
      <mat-expansion-panel-header class="
      us:
      us-landscape:
      md:h-height-12-md
      md-landscape:
      lg:
      lg-landscape:h-height-18
      xl-landscape:">
        <p class="relative font-PoppinsRegular font-normal
        us:
        us-landscape:
        md:text-font-18-md md:
        md-landscape:
        lg:
        lg-landscape:text-font-28 lg-landscape:leading-leading-28
        xl-landscape:">Do all Foodtrucks have
          health permits and insurance?
        </p>
      </mat-expansion-panel-header>
      <p class="font-PoppinsLight font-light
      us:
      us-landscape:
      md:text-font-14-md md:
      md-landscape:
      lg:
      lg-landscape:text-font-18 lg-landscape:leading-leading-30
      xl-landscape:"><a class="font-PoppinsBold">To make sure the food served at your event is safe and meets health
          standards, GutTrucks only works with food trucks that have a health permit and insurance. We keep proof of
          their insurance on file and can provide you with a copy if needed. However, you will need to get any
          additional permits required by the venue or government for your event. Check with the venue or government
          agencies to make sure you have all the necessary permits.</a>
      </p>
    </mat-expansion-panel>

  </mat-accordion>

</section>

<section id="contactUs" class="relative bg-contactUs
        us:
        us-landscape:
        md:h-auto
        md-landscape:
        lg:
        lg-landscape:h-height-270
        xl-landscape:">
  <div class="relative flex justify-center
        us:
        us-landscape:
        md:pt-padding-14-md
        md-landscape:
        lg:
        lg-landscape:pt-padding-28-lg
        xl-landscape:">
    <div class="absolute rounded-full overflow-hidden transform rotate-135
        us:
        us-landscape:
        md:top-12-md md:left-98-md md:h-height-6-md md:w-width-6-md md:mb-margin-3-md
        md-landscape:
        lg:
        lg-landscape:top-25 lg-landscape:left-187 lg-landscape:h-height-8 lg-landscape:w-width-8 lg-landscape:mb-margin-10
        xl-landscape:">
      <div class="relative h-50%"></div>
      <div class="relative h-50% bg-red-primary"></div>
    </div>

    <h3 class="relative text-gray-333333 font-PoppinsBold font-bold
        us:
        us-landscape:
        md:text-font-36-md md:leading-leading-40-md
        md-landscape:
        lg:
        lg-landscape:text-font-64 lg-landscape:leading-leading-64
        xl-landscape:">Contact Us</h3>

    <div class="absolute rounded-full overflow-hidden transform rotate-135
        us:
        us-landscape:
        md:left-152.5-md md:top-20-md md:h-height-6-md md:w-width-6-md
        md-landscape:
        lg:
        lg-landscape:left-281.2 lg-landscape:top-38.7 lg-landscape:h-height-8 lg-landscape:w-width-8
        xl-landscape:">
      <div class="relative h-50% bg-red-primary"></div>
      <div class="relative h-50%"></div>
    </div>
  </div>

  <p class="relative text-red-primary font-PoppinsRegular font-normal text-center
        us:
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md md:mt-margin-3-md
        md-landscape:
        lg:
        lg-landscape:text-font-28 lg-landscape:leading-leading-28 lg-landscape:mt-margin-4
        xl-landscape:">
    Get in touch with us to book a food truck today!</p>


  <div class="relative flex
  us:
        us-landscape:
        md:ml-margin-24-md md:mt-margin-14-md
        md-landscape:
        lg:
        lg-landscape:ml-margin-88.2 lg-landscape:mt-margin-39
        xl-landscape:">
    <div class="relative flex flex-col items-end">
      <div class="relative flex
      us:
        us-landscape:
        md:gap-x-gap-4-md md:mb-margin-4-md
        md-landscape:
        lg:
        lg-landscape:gap-x-gap-6 lg-landscape:mb-margin-6
        xl-landscape:">
        <input type="text" placeholder="Your Name" class="relative bg-gray-FFFFFF border-gray-DADADA border-1 font-PoppinsRegular font-normal text-gray-828282
          us:
        us-landscape:
        md:h-height-11-md md:w-width-57-md md:rounded-3xl md:px-padding-3-md md:text-font-16-md md:leading-leading-24-md
        md-landscape:
        lg:
        lg-landscape:h-height-12.5 lg-landscape:w-width-84 lg-landscape:rounded-3xl lg-landscape:px-padding-6 lg-landscape:text-base
        xl-landscape:">
        <input type="text" placeholder="Your Email" class="relative bg-gray-FFFFFF border-gray-DADADA border-1 font-PoppinsRegular font-normal  text-gray-828282
          us:
        us-landscape:
        md:h-height-11-md md:w-width-64-md md:rounded-3xl md:px-padding-3-md md:text-font-16-md md:leading-leading-24-md
        md-landscape:
        lg:
        lg-landscape:h-height-12.5 lg-landscape:w-width-96.2 lg-landscape:rounded-3xl lg-landscape:text-base lg-landscape:px-padding-6
        xl-landscape:">
      </div>

      <textarea name="sugestion" id="" cols="30" rows="10" placeholder="How can we help you?" class="relative bg-gray-FFFFFF border-gray-DADADA border-1 font-PoppinsRegular font-normal text-gray-828282
        us:
        us-landscape:
        md:h-height-40-md md:w-width-125-md md:rounded-3xl md:text-font-16-md md:leading-leading-24-md md:px-padding-3-md md:py-padding-2-md
        md-landscape:
        lg:
        lg-landscape:h-height-73.5 lg-landscape:w-width-186.5 lg-landscape:rounded-3xl lg-landscape:text-base lg-landscape:px-padding-6 lg-landscape:py-padding-3.5
        xl-landscape:"></textarea>

      <button class="relative bg-red-primary text-white font-PoppinsBold
        us:
        us-landscape:
        md:rounded-3xl md:h-height-10-md md:w-width-36-md md:text-font-16-md md:leading-leading-24-md md:mt-margin-6-md md:mb-margin-24-md
        md-landscape:
        lg:
        lg-landscape:rounded-3xl lg-landscape:h-height-12.5 lg-landscape:w-width-50 lg-landscape:text-font-24 lg-landscape:leading-leading-36 lg-landscape:mt-margin-12 lg-landscape:mb-margin-58.75
        xl-landscape:">Send</button>
    </div>

    <div class="relative w-full h-full ">
      <div class="relative flex items-center
      us:
        us-landscape:
        md:ml-margin-3-md
        md-landscape:
        lg:
        lg-landscape:mb-margin-3.5 lg-landscape:ml-margin-7
        xl-landscape:">
        <img class="relative" src="../../../../assets/imgs/home/icon-email.svg" alt="food truck email">
        <p class="relative font-PoppinsRegular font-medium text-gray-333333
        us:
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md md:ml-margin-3-md
        md-landscape:
        lg:
        lg-landscape:text-font-20 lg-landscape:leading-leading-20 lg-landscape:ml-margin-4
        xl-landscape:">EMAIL
        </p>
      </div>
      <a class="relative font-PoppinsRegular font-normal text-gray-333333 underline
      us:
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md md:ml-margin-15-md
        md-landscape:
        lg:
        lg-landscape:text-font-18 lg-landscape:leading-leading-24 lg-landscape:ml-margin-20
        xl-landscape:" href="mailto:support@guttrucks.com">support@guttrucks.com</a>

      <div class="relative flex items-center
      us:
        us-landscape:
        md:mt-margin-6-md md:ml-margin-3-md
        md-landscape:
        lg:
        lg-landscape:mt-margin-12 lg-landscape:mb-margin-3.5 lg-landscape:ml-margin-7
        xl-landscape:">
        <img class="relative" src="../../../../assets/imgs/home/icon-phone.svg" alt="food truck phone">
        <p class="relative font-PoppinsRegular font-medium text-gray-333333
        us:
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md md:ml-margin-3-md
        md-landscape:
        lg:
        lg-landscape:text-font-20 lg-landscape:leading-leading-20 lg-landscape:ml-margin-4
        xl-landscape:">PHONE
          NUMBER</p>
      </div>
      <a class="relative font-PoppinsRegular font-normal text-gray-333333
        us-landscape:
        md:text-font-16-md md:leading-leading-24-md md:ml-margin-15-md
        md-landscape:
        lg:
        lg-landscape:text-font-18 lg-landscape:leading-leading-21 lg-landscape:ml-margin-20
        xl-landscape:">+1 (678) 877–9047</a>
    </div>

  </div>
</section>

<app-footer></app-footer>