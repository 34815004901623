import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { AlgoliaFireService } from './shared/services/algolia.service';
import { DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NotificationsService } from './shared/services/notifications.service';
import { MapsAPILoader } from '@agm/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'guttrucks';
  google_api_loaded = false;

  constructor(
    private algoliaService: AlgoliaFireService,
    @Inject(DOCUMENT) private document: Document,
    private deviceService: DeviceDetectorService,
    private notifications_Service: NotificationsService,
    private mapsAPILoader: MapsAPILoader,
  ) { }

  ngOnInit(): void {
    this.algoliaService.ngOnInit();
    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      window?.location?.assign("https://guttrucksapp.page.link/apps");
    }
  }

  ngAfterViewInit(): void {
    this.mapsAPILoader.load().then(() => {
      if (google.maps.places && google) {
        this.google_api_loaded = true;
      }
    })
      .catch((err) => { 
        console.log('El error de google es ' + err);
        
      })
  }

}
