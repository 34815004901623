<div class="relative w-auto h-auto bg-red-930031
md:pt-20 md:pb-20 md:pl-14
md-landscape:
lg:pt-255px lg:pb-240px lg:pl-200px
lg-landscape:pt-255px lg-landscape:pb-240px lg-landscape:pl-200px
xl-landscape:pt-255px xl-landscape:pb-240px xl-landscape:pl-200px">

  <div class="relative
  md:w-418px
  md-landscape:
  lg:w-937px lg:h-240px
  lg-landscape:w-937px lg-landscape:h-240px
  xl-landscape:w-937px xl-landscape:h-240px">
    <p class="relative text-white font-PoppinsMedium font-bold
    md:text-font-5.68vw-md
    md-landscape:
    lg:text-font-96
    lg-landscape:text-font-96
    xl-landscape:text-font-96">
      Apply for upcoming events
    </p>
  </div>

  <div class="relative flex
  md:
  md-landscape:
  lg:w-937px lg:h-190px lg:mt-155px
  lg-landscape:w-937px lg-landscape:h-190px lg-landscape:mt-155px
  xl-landscape:w-937px xl-landscape:h-190px xl-landscape:mt-155px">

    <img class="relative
    md:h-auto md:w-36
    md-landscape:
    lg:h-190px lg:w-186px
    lg-landscape:h-190px lg-landscape:w-186px
    xl-landscape:h-190px xl-landscape:w-186px" src="../../../assets/imgs/owners/group1.png" alt="">

    <div class="relative flex flex-col
    md:ml-7
    md-landscape:
    lg:ml-margin-12
    lg-landscape:ml-margin-12
    xl-landscape:ml-margin-12">

      <p class="relative text-white font-PoppinsBold font-bold
      md:text-lg md:mb-6
      md-landscape:
      lg:text-36px lg:mb-margin-6 lg:leading-normal
      lg-landscape:text-36px lg-landscape:mb-margin-6 lg-landscape:leading-normal
      xl-landscape:text-36px xl-landscape:mb-margin-6 xl-landscape:leading-normal">
        Gut Trucks App for Owners <br> Get only the events that fit your criteria
      </p>

      <div class="relative flex
      md:
      md-landscape:
      lg:h-33px lg:w-auto lg:gap-x-gap-36px
      lg-landscape:h-48px lg-landscape:w-auto lg-landscape:gap-x-gap-36px
      xl-landscape:h-48px xl-landscape:w-auto xl-landscape:gap-x-gap-36px">

        <a class="relative w-auto h-auto" href="https://play.google.com/store/apps/details?id=com.guttrucks.owner">
          <img class="relative
        md:
        md-landscape:
        lg:w-190px lg:h-auto lg:rounded-4
        lg-landscape:w-190px lg-landscape:h-auto lg-landscape:rounded-md
        xl-landscape:w-190px xl-landscape:h-auto xl-landscape:rounded-md" src="../../../assets/imgs/Group 1187.jpg"
            alt="">
        </a>

        <a class="relative w-auto h-auto" href="https://apps.apple.com/us/app/guttrucks-for-owners/id1563462091">
          <img class="relative
          md:
          md-landscape:
          lg:w-170px lg:h-auto lg:rounded-5.2
          lg-landscape:w-170px lg-landscape:h-auto lg-landscape:rounded-10
          xl-landscape:w-170px xl-landscape:h-auto xl-landscape:rounded-10" src="../../../assets/imgs/Group 1188.jpg"
            alt="">
        </a>

      </div>

    </div>

  </div>


  <img class="absolute z10
  md:right-0 md:bottom-0 md:w-20
  md-landscape:
  lg:right-0 lg:bottom-0 lg:w-794px lg:h-670px
  lg-landscape:right-0 lg-landscape:bottom-0 lg-landscape:w-794px lg-landscape:h-670px
  xl-landscape:right-0 xl-landscape:bottom-0 xl-landscape:w-794px xl-landscape:h-670px"
    src="../../../assets/imgs/owners/truck.png" alt="">

</div>