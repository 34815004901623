import { Injectable, OnInit } from '@angular/core';
import { Storage } from '@angular/fire/storage';
import { Firestore, collectionData, collection, doc, getDoc, query, where, getDocs, WhereFilterOp } from '@angular/fire/firestore';
import { httpsCallable, getFunctions } from '@angular/fire/functions';
import { firstValueFrom, Observable, from } from "rxjs";
import algoliasearch from 'algoliasearch/lite';
import { environment } from 'src/environments/environment';
import { CollectionName } from '../interfaces/collections.names';

const searchClient = algoliasearch(
  environment.algoliaId,
  environment.algoliaKey
);

@Injectable({
  providedIn: 'root'
})

export class AlgoliaFireService implements OnInit {
  index = searchClient.initIndex(environment.algoliaApp);
  collectionRef: any;
  cuisineTypes: any = [];

  constructor(
    private storage: Storage,
    private db: Firestore,
  ) {

  }

  ngOnInit() {
    // this.getCuisineTypes();
    // let arr = await this.getCollectionWithoutListener('cuisineType');
    // console.log(arr);
    // })
  }

  get fire_storage() {
    return this.storage;
  }

  get cuisines() {
    return this.cuisineTypes;
  }

  getCallendarBookings(ownerId: string, actual_date_in_seconds: number) {
    let now = new Date(Date.now());
    const q = query(collection(this.db, 'booking'),
      where("ownerId", "==", ownerId),
      where("endDate", ">", now),//TODO aqui tengo q ver como le paso el dia de hoy para q no me obvie los eventos del dia
      where("isDeleted", "==", false));
    const queryObservable = from(getDocs(q));
    return queryObservable;
  }

  getCollectionWithFilters(cuisineType: string, filters?: []) {
    const q = query(collection(this.db, `${cuisineType}`), where("isDeleted", "==", false));
    const queryObservable = from(getDocs(q));
    return queryObservable;
  }

  getCollectionWithDynamicFilter(collectionName: string, field: string, operator: WhereFilterOp, value: any) {
    const q = query(collection(this.db, `${collectionName}`), where(`${field}`, `${operator}`, value));
    const queryObservable = from(getDocs(q));
    return queryObservable;
  }

  getCollectionWithListener(collection_name: string, idField: boolean): Observable<any> {
    const returnId = idField == true ? { idField: '_id' } : {};
    const COLLECTION = collection(this.db, `${collection_name}`);
    return collectionData(COLLECTION, returnId);
  };

  async getCollectionWithoutListener(collection_name: string, idField: boolean) {
    const returnId = idField == true ? { idField: '_id' } : {};
    let result: any = [];
    const COLLECTION = collection(this.db, `${collection_name}`);
    try {
      result = await firstValueFrom(collectionData(COLLECTION, returnId))
    } catch (error) {
      console.log(error);
    }
    return result;
  }

  getCuisineTypes(): void {
    this.getCollectionWithListener(CollectionName.CUISINE_TYPE, true).subscribe(
      {
        next: (data: any) => {
          this.cuisineTypes = data;

          // console.log(this.cuisineTypes);
        },
        error: (err => {
          console.log(err);
        })
      }
    );
  }

  async getCuisineGeneralTypeById(ids: string[]): Promise<string> {
    let result = "";
    ids.forEach((element, index: any) => {
      this.cuisineTypes.forEach((cuisine: any) => {
        if (element == cuisine._id && !result.includes(cuisine?.name[1]?.value)) result = result + `${cuisine?.name[1]?.value}${index == ids.length - 1 ? '' : ', '}`;
      });
    });
    return result;
  }

  async getCuisineGeneralTypeByIdReturnArray(ids: string[]): Promise<[]> {
    let result: any = [];
    ids.forEach((element, index: any) => {
      this.cuisineTypes.forEach((cuisine: any) => {
        if (element == cuisine._id) result.push(`${cuisine?.name[1]?.value}`);
      })
    });
    // console.log(result);

    return result;
  }


  getElementById(id: any, collection_name: string): Observable<any> {
    const COLLECTION = doc(this.db, `${collection_name}/${id}`);
    return from(getDoc(COLLECTION));
  };

  callCloudFunction(function_name: string, params: any) {
    const functions = getFunctions();
    const fns = httpsCallable(functions, 'addMessage');
    fns(params)
      .then((result) => {
        const data = result.data;
        console.log(data);
        // const sanitizedMessage = data.text;
      });
  }

}
