import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-only-map',
  templateUrl: './only-map.component.html',
  styleUrls: ['./only-map.component.scss']
})
export class OnlyMapComponent implements OnInit, AfterViewInit {
  @Input() next_events: any;
  map: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.loadMap();
  }

  loadMap(): void {
    let event: any = {};
    this.next_events.forEach((element: any) => {
      if (element?.address?.geometry?.location?.lat !== undefined && element?.address?.geometry?.location?.lng !== undefined) {
        event = element;
      }
    });

    this.map = L.map('only-map')
      .setView(
        [event?.address?.geometry?.location?.lat, event?.address?.geometry?.location?.lng],
        10);
    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      // attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 20,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoiYWN1Y3RlYyIsImEiOiJja2xkYWlzOWgwZ28zMnhwN2h6MTExY2FwIn0.xA8q4gZl2qVnbXdneSFRig',
    }).addTo(this.map);
    this.createMarkers(this.next_events);
  }

  createMarkers(data: any[]) {
    // console.log(data);
    const icon = L.icon({
      iconUrl: '../../../../assets/icons/map/marker-icon.png',
      iconSize: [25, 41],
      iconAnchor: [12.5, 41],
      // shadowUrl: 'assets/images/marker-shadow.png',
      popupAnchor: [0, -41],
    });
    data.forEach((element: any) => {
      if (element?.address?.geometry?.location?.lat != undefined && element?.address?.geometry?.location?.lng != undefined) {
        // console.log(element.title + '-----' + element?.address?.geometry?.location?.lng);
        const marker = L.marker([element?.address?.geometry?.location?.lat, element?.address?.geometry?.location?.lng], { icon })
          .bindPopup(element?.title, {
            closeButton: false
          });
        marker.addTo(this.map);
      }
    });
  }

}
