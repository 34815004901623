import { Pipe, PipeTransform } from '@angular/core';
import { AlgoliaFireService } from '../services/algolia.service';

@Pipe({
    name: 'getMenuSub'
})

export class GetMenuSubName implements PipeTransform {

    constructor() {

    }

    transform(id: string, arr: any[]) {
        let result = '';
        arr.forEach((element: any) => {
            if (element.id == id) result = element.name;
        });
        return result;
    }
}