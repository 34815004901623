import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  type: string = '';
  msg: string = '';
  btn_text: String = '';

  constructor(
    private dialogRef: MatDialogRef<NotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: string, msg: string, btn_text: string },
  ) { }

  ngOnInit(): void {
    this.type = this.data?.type;
    this.msg = this.data?.msg;
    this.btn_text = this.data?.btn_text;
  }

  close() {
    this.dialogRef.close();
  }

  closeAndRedirect() {
    this.dialogRef.close(true);
  }

}
