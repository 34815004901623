import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable, Subscriber } from "rxjs";
import { TrucksService } from "src/app/trucks/services/trucks.service";
import { AlgoliaFireService } from "../../services/algolia.service";
import { NotificationsService } from "../../services/notifications.service";
import { MapComponent } from "../map/map.component";
import { ModalMapComponent } from "../modal-map/modal-map.component";

@Component({
  selector: "app-search",
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
  distances: number[] = [25, 50, 75, 100, 125, 150, 175, 200, 300, 1000];
  query: string = '';
  hits: any = [];
  bigGridHits: any = [];
  american_hits: any = [];
  bbq_hits: any = [];
  mexican_hits: any = [];
  top_hits: any = [];
  latlng = {
    lat: 0,
    lng: 0
  };
  pages_1 = 1;
  pages_1_numbers: any = [];
  testing = [];
  collection: any;
  showHits = false;
  booking = false;
  pickup = false;
  cuisines: any = [];
  cuisine_filter = false;
  distance_filter = false;
  date_filter = false;
  cuisine_filter_value: any = null;
  distance_filter_value: any = null;
  date_filter_value: any = null;
  skeletons_grids: any = [1, 2, 3, 4, 5];
  search_skeleton = false;


  address: any = 'Use current location';
  constructor(
    public algoliaService: AlgoliaFireService,
    private router: Router,
    private dialog: MatDialog,
    private notifications: NotificationsService,
    public truckService: TrucksService,
  ) {
    if (sessionStorage.getItem('address')) {
      this.address = sessionStorage.getItem('address')
    }
  }

  ngOnInit(): void {
    window.scroll({ top: 0 });
    if (sessionStorage.getItem('lat')) this.latlng.lat = Number(sessionStorage.getItem('lat'));
    if (sessionStorage.getItem('lng')) this.latlng.lng = Number(sessionStorage.getItem('lng'));
    if (sessionStorage.getItem('address')) this.address = sessionStorage.getItem('address');
  }

  ngAfterViewInit(): void {
    this.getCuisineTypes();
    document.querySelector('#search-body')?.addEventListener('mouseover', (event) => {
      // sessionStorage.getItem('address') ? '' : this.getDeviceLocation();
      this.getDeviceLocation();
    }, { once: true });
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

  getDeviceLocation() {
    if (!sessionStorage.getItem('address')) {
      this.selectOnMap();
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(res => {
      //     this.latlng.lat = res.coords.latitude;
      //     this.latlng.lng = res.coords.longitude;
      //   });
      // }else{
      //   alert('Navigator geolocation unabled')
      // }

    } else {
      this.latlng.lat = Number(sessionStorage.getItem('lat'));
      this.latlng.lng = Number(sessionStorage.getItem('lng'));
      this.loadGrids();
    }
  }

  // getCurrentPosition(): any {
  //   return new Observable((observer: Subscriber<any>) => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition((position: any) => {
  //         observer.next({
  //           latitude: position.coords.latitude,
  //           longitude: position.coords.longitude,
  //         });
  //         observer.complete();
  //       });
  //     } else {
  //       observer.error();
  //     }
  //   });
  // }

  async search(event: any) {
    this.query = event.target?.value;
    let lat_lng_filters = {};
    if (this.latlng.lat != 0 && this.latlng.lng != 0) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundLatLng: `${this.latlng.lat}, ${this.latlng.lng}`
      }
    }
    if (this.distance_filter_value !== null) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundRadius: this.distance_filter_value,
      }
    }
    try {
      if (this.query !== '') {
        this.search_skeleton = true;
        const result = await this.algoliaService.index.search(this.query, {
          // attributesToRetrieve: ['cuisineTypes'],
          filters: `${this.cuisine_filter_value !== null ? this.cuisine_filter_value : ''}`,
          getRankingInfo: true,
          hitsPerPage: 5,
          ...lat_lng_filters
        });
        this.paginate_1(result?.nbPages);
        this.search_skeleton = false;
        this.hits = result?.hits;
      } else {
        this.hits = [];
      }
    } catch (error: any) {
      console.log(error?.message);
      this.search_skeleton = false;
    }
  }

  async searchWithoutPagination() {
    let lat_lng_filters = {};
    if (this.latlng.lat != 0 && this.latlng.lng != 0) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundLatLng: `${this.latlng.lat}, ${this.latlng.lng}`
      }
    }
    if (this.distance_filter_value !== null) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundRadius: this.distance_filter_value,
      }
    }
    try {
      this.search_skeleton = true;
      const result = await this.algoliaService.index.search(this.query, {
        // attributesToRetrieve: ['cuisineTypes'],
        filters: `${this.cuisine_filter_value !== null ? this.cuisine_filter_value : ''}`,
        getRankingInfo: true,
        // hitsPerPage: 10,
        ...lat_lng_filters
      });
      this.paginate_1(result?.nbPages);
      this.search_skeleton = false;
      this.hits = result?.hits;
    } catch (error: any) {
      console.log(error?.message);
      this.search_skeleton = false;
    }
  }

  paginate_1(pages: number) {
    this.pages_1_numbers = [];
    for (let index = 0; index < pages; index++) {
      this.pages_1_numbers.push(index);
    }
  }

  async gotoPage(page: number) {
    try {
      const result = await this.algoliaService.index.search(this.query, {
        // aroundLatLng: '25.7828556, -80.1324173',
        // aroundRadius: 520000,
        // getRankingInfo: true,
        hitsPerPage: 5,
        page: page
      });
      this.hits = result?.hits;
    } catch (error: any) {
      console.log(error?.message);
    }
  }

  getCuisineTypes() {
    this.algoliaService.getCollectionWithFilters('cuisineType').subscribe(
      {
        next: (data: any) => {

          this.algoliaService.cuisineTypes = data?.docs?.map((v: any) => {
            return { ...v.data(), _id: v.id }
          })
          this.loadGrids();
        },
        error: (err => {
          console.log(err);
        })
      }
    )
  }

  viewDetails(truck: any) {
    return this.router.navigateByUrl(`/truck/details/${truck.objectID}/${truck?._rankingInfo?.geoDistance}`);
  }

  changeColor(num: number) {
    switch (num) {
      case 1:
        this.booking = true;
        this.pickup = false;
        break;
      case 2:
        this.booking = false;
        this.pickup = true;
        break;

      default:
        break;
    }
  }

  selectFilter(num: number) {
    switch (num) {
      case 1:
        if (this.cuisine_filter == true) {
          this.cuisine_filter = false;
        } else {
          this.cuisine_filter = true;
          this.distance_filter = false;
          this.date_filter = false;
        }
        break;
      case 2:
        if (this.distance_filter == true) {
          this.distance_filter = false;
        } else {
          this.cuisine_filter = false;
          this.distance_filter = true;
          this.date_filter = false;
        }
        break;
      case 3:
        if (this.date_filter == true) {
          this.date_filter = false;
        } else {
          this.cuisine_filter = false;
          this.distance_filter = false;
          this.date_filter = true;
        }
        break;
      default:
        break;
    }
  }

  selectCuisineFilter(cuisine: any) {
    cuisine == null ? this.cuisine_filter_value = null : this.cuisine_filter_value = `cuisineTypes:${cuisine?._id}`;
    this.searchWithFilter();
  }

  selectDistance(distance: number) {
    this.distance_filter_value = (distance * 1609);/*Miles to meters*/
    this.searchWithFilter();
  }

  async loadGrids() {
    let lat_lng_filters = {};
    if (this.latlng.lat != 0 && this.latlng.lng != 0) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundLatLng: `${this.latlng.lat}, ${this.latlng.lng}`
      }
    }
    if (this.distance_filter_value !== null) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundRadius: this.distance_filter_value,
      }
    }

    let american = this.algoliaService.cuisineTypes?.find((element: any) => {
      return element?.name[0].value == 'American';
    });
    let bbq = this.algoliaService.cuisineTypes?.find((element: any) => {
      return element?.name[0].value == 'BBQ';
    });
    let mexican = this.algoliaService.cuisineTypes?.find((element: any) => {
      return element?.name[0].value == 'Mexican';
    });



    let load_top: Promise<any> = this.algoliaService.index.search('', { hitsPerPage: 5, getRankingInfo: true, ...lat_lng_filters });
    let load_american: Promise<any> = this.algoliaService.index.search('', {
      filters: `cuisineTypes:${american?._id}`,
      hitsPerPage: 5, getRankingInfo: true, ...lat_lng_filters
    });
    let load_BBQ: Promise<any> = this.algoliaService.index.search('', {
      filters: `cuisineTypes:${bbq?._id}`,
      hitsPerPage: 5, getRankingInfo: true, ...lat_lng_filters
    });
    let load_mexican: Promise<any> = this.algoliaService.index.search('', {
      filters: `cuisineTypes:${mexican?._id}`,
      hitsPerPage: 5, getRankingInfo: true, ...lat_lng_filters
    });
    try {
      this.top_hits = [];
      this.american_hits = [];
      this.bbq_hits = [];
      this.mexican_hits = [];
      let responses = await Promise.all([load_top, load_american, load_BBQ, load_mexican]);
      this.top_hits = responses[0]?.hits;
      this.american_hits = responses[1]?.hits;
      this.bbq_hits = responses[2]?.hits;
      this.mexican_hits = responses[3]?.hits;
      this.showHits = true;
    } catch (error) {
      console.log(error);
    }
  }

  async loadGridsWithoutPagination() {
    let lat_lng_filters = {};
    if (this.latlng.lat != 0 && this.latlng.lng != 0) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundLatLng: `${this.latlng.lat}, ${this.latlng.lng}`
      }
    }
    if (this.distance_filter_value !== null) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundRadius: this.distance_filter_value,
      }
    }

    let load_top: Promise<any> = this.algoliaService.index.search('', { hitsPerPage: 5, getRankingInfo: true, ...lat_lng_filters });
    let load_american: Promise<any> = this.algoliaService.index.search('', {
      filters: 'cuisineTypes:8SSDO545J91FQCGQSfDs',
      getRankingInfo: true, ...lat_lng_filters
    });
    let load_BBQ: Promise<any> = this.algoliaService.index.search('', {
      filters: 'cuisineTypes:AmB0C12oouBX9ZJlnE5T',
      getRankingInfo: true, ...lat_lng_filters
    });
    let load_mexican: Promise<any> = this.algoliaService.index.search('', {
      filters: 'cuisineTypes:4dqCd1ZW5Doa1PtKNKm6',
      getRankingInfo: true, ...lat_lng_filters
    });
    try {
      this.top_hits = [];
      this.american_hits = [];
      this.bbq_hits = [];
      this.mexican_hits = [];
      let responses = await Promise.all([load_top, load_american, load_BBQ, load_mexican]);
      this.top_hits = responses[0]?.hits;
      this.american_hits = responses[1]?.hits;
      this.bbq_hits = responses[2]?.hits;
      this.mexican_hits = responses[3]?.hits;
      this.showHits = true;
    } catch (error) {
      console.log(error);
    }
  }

  async searchWithFilter() {
    let lat_lng_filters = {};
    if (this.latlng.lat != 0 && this.latlng.lng != 0) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundLatLng: `${this.latlng.lat}, ${this.latlng.lng}`
      }
    }
    if (this.distance_filter_value !== null) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundRadius: this.distance_filter_value,
      }
    }
    try {
      this.search_skeleton = true;
      const result = await this.algoliaService.index.search(this.query, {
        // attributesToRetrieve: ['cuisineTypes'],
        getRankingInfo: true,
        filters: `${this.cuisine_filter_value !== null ? this.cuisine_filter_value : ''}`,
        hitsPerPage: 10,
        ...lat_lng_filters
      });
      this.paginate_1(result?.nbPages);
      this.search_skeleton = false;
      this.hits = result?.hits;
    } catch (error: any) {
      this.search_skeleton = false;
      console.log(error?.message);
    }
  }

  selectOnMap() {
    let dialoRef = this.dialog.open(MapComponent, {
      // backdropClass: ,
      disableClose: true,
      // width:'auto',
      data: this.latlng
    })

    dialoRef.afterClosed().subscribe({
      next: ((data: any) => {

        if (data?.lat != 0 && data?.lng != 0) {
          this.latlng.lat = data?.lat;
          this.latlng.lng = data?.lng;
          sessionStorage.setItem('lat', this.latlng.lat.toString());
          sessionStorage.setItem('lng', this.latlng.lng.toString());
          this.search({
            target: {
              value: this.query
            }
          })
          this.loadGrids();
          if (sessionStorage.getItem('address')) {
            this.address = sessionStorage.getItem('address')
          }
        }

        if (sessionStorage.getItem('lat') && sessionStorage.getItem('lng')) {
          this.latlng.lat = Number(sessionStorage.getItem('lat'));
          this.latlng.lng = Number(sessionStorage.getItem('lng'));
          sessionStorage.setItem('lat', this.latlng.lat.toString());
          sessionStorage.setItem('lng', this.latlng.lng.toString());
          this.search({
            target: {
              value: this.query
            }
          })
          this.loadGrids();
          if (sessionStorage.getItem('address')) {
            this.address = sessionStorage.getItem('address')
          }
        }

        if ((data?.lat == 0 && data?.lng == 0) && (!sessionStorage.getItem('lat') && !sessionStorage.getItem('lng'))) {
          this.notifications.showAndSubscribe('Error', 'For a better use of our services, please insert your location.', 'Accept').afterClosed().subscribe((data) => {
            this.selectOnMap();
            // this.getCurrentLocation();
          })
        }
      }),
      error: (err => {
        console.log(err);
      })
    })
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(res => {
        this.latlng.lat = res.coords.latitude;
        this.latlng.lng = res.coords.longitude;
        this.address = 'Device location';
        // this.truckService.isInArr(this.address) ? null : this.truckService.previous_directions.unshift({ formatted_address: this.address });
        sessionStorage.setItem('lat', this.latlng.lat.toString());
        sessionStorage.setItem('lng', this.latlng.lng.toString());
        sessionStorage.setItem('address', this.address);
        setTimeout(() => {
          this.search({
            target: {
              value: this.query
            }
          })
          this.loadGrids();
        }, 200);
      });
    }
  }

  showOnMap() {
    let dialoRef = this.dialog.open(ModalMapComponent, {
      // backdropClass: ,
      disableClose: true,
      // width:'auto',
      data: this.latlng
    })

    dialoRef.afterClosed().subscribe({
      next: ((data: any) => {


      }),
      error: (err => {
        console.log(err);
      })
    })
  }
  //erne logic trials

  async loadFullAmericanGrid() {
    let lat_lng_filters = {};
    if (this.latlng.lat != 0 && this.latlng.lng != 0) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundLatLng: `${this.latlng.lat}, ${this.latlng.lng}`
      }
    }
    if (this.distance_filter_value !== null) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundRadius: this.distance_filter_value,
      }
    }

    let american = this.algoliaService.cuisineTypes?.find((element: any) => {

      return element?.name[0].value == 'American';
    });


    let load_american: Promise<any> = this.algoliaService.index.search('', {
      filters: `cuisineTypes:${american?._id}`,
      hitsPerPage: 30, getRankingInfo: true, ...lat_lng_filters
    });

    try {
      this.american_hits = [];
      let responses = await Promise.all([load_american]);
      this.american_hits = responses[0]?.hits;
      this.showHits = true;
    } catch (error) {
      console.log(error);
    }
  }

  async loadFullMexicanGrid() {
    let lat_lng_filters = {};
    if (this.latlng.lat != 0 && this.latlng.lng != 0) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundLatLng: `${this.latlng.lat}, ${this.latlng.lng}`
      }
    }
    if (this.distance_filter_value !== null) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundRadius: this.distance_filter_value,
      }
    }
    let mexican = this.algoliaService.cuisineTypes?.find((element: any) => {
      return element?.name[0].value == 'Mexican';
    });

    let load_mexican: Promise<any> = this.algoliaService.index.search('', {
      filters: `cuisineTypes:${mexican?._id}`,
      hitsPerPage: 30, getRankingInfo: true, ...lat_lng_filters
    });
    try {
      this.mexican_hits = [];
      let responses = await Promise.all([load_mexican]);
      this.mexican_hits = responses[0]?.hits;
      this.showHits = true;
    } catch (error) {
      console.log(error);
    }
  }

  async loadFullBbqGrid() {
    let lat_lng_filters = {};
    if (this.latlng.lat != 0 && this.latlng.lng != 0) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundLatLng: `${this.latlng.lat}, ${this.latlng.lng}`
      }
    }
    if (this.distance_filter_value !== null) {
      lat_lng_filters = {
        ...lat_lng_filters,
        aroundRadius: this.distance_filter_value,
      }
    }

    let bbq = this.algoliaService.cuisineTypes?.find((element: any) => {
      return element?.name[0].value == 'BBQ';
    });

    let load_BBQ: Promise<any> = this.algoliaService.index.search('', {
      filters: `cuisineTypes:${bbq?._id}`,
      hitsPerPage: 30, getRankingInfo: true, ...lat_lng_filters
    });

    try {
      this.bbq_hits = [];
      let responses = await Promise.all([load_BBQ]);
      this.bbq_hits = responses[0]?.hits;
      this.showHits = true;
    } catch (error) {
      console.log(error);
    }
  }

  // pruebas para el load MOre



}

