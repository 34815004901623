import { NgModule } from '@angular/core';
import { GetCuisineGeneralType } from '../../pipes/getCuisineGeneralType.Pipe';
import { GetMenuSubName } from '../../pipes/getMenuSubName.pipe';
import { GsToHttpsComparisson } from '../../pipes/getUrlFromComparison.pipe';
import { GsToHttps } from '../../pipes/gsToUrl.Pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TwoDecimalsMetersToMiles } from '../../pipes/twoDecimalsMetersToMiles.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { GetCuisineGeneralTypeAndReturnArray } from '../../pipes/getCuisinesTypeAndReturnArray.pipe';
import { OnlyMapComponent } from '../../../shared/components/only-map/only-map.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DatePipe } from '../../pipes/secondsToDate.Pipe';




@NgModule({
  declarations: [
    GsToHttps,
    GetCuisineGeneralType,
    GetMenuSubName,
    GsToHttpsComparisson,
    TwoDecimalsMetersToMiles,
    GetCuisineGeneralTypeAndReturnArray,
    DatePipe,
    OnlyMapComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxSkeletonLoaderModule],
  providers: [
    { provide: GsToHttps },
    { provide: GetCuisineGeneralType },
    { provide: GetMenuSubName },
    { provide: GsToHttpsComparisson },
    { provide: TwoDecimalsMetersToMiles },
    { provide: GetCuisineGeneralTypeAndReturnArray },
    { provide: DatePipe },
  ],
  exports: [
    GsToHttps,
    GetCuisineGeneralType,
    GetMenuSubName,
    GsToHttpsComparisson,
    TwoDecimalsMetersToMiles,
    GetCuisineGeneralTypeAndReturnArray,
    DatePipe,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    OnlyMapComponent,
    NgxSkeletonLoaderModule
  ]
})
export class SharedModule { }
