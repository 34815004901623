<div class="relative w-full h-auto
            us:px-3
            us-landscape:
            md:px-4
            md-landscape:
            lg:
            lg-landscape:pl-body-lateral-left-padding-lg lg-landscape:pr-body-lateral-right-padding-lg lg-landscape:mt-32 lg-landscape:pb-10
            xl-landscape:pb-12 xl-landscape:pl-body-lateral-padding xl-landscape:lg-landscape:pr-body-lateral-padding xl-landscape:mt-36">
  <app-search></app-search>
</div>
