import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainComponent } from './base-components/main/main.component';
import { OwnersComponent } from './base-components/owners/owners.component';
import { HomeComponent } from './shared/components/home/home.component';
import { MenuComponent } from './base-components/menu/menu.component';
import { FooterComponent } from './base-components/footer/footer.component';
import { AllGridComponent } from './shared/components/all-grid/all-grid.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'search', component: MainComponent },
  { path: 'owners', component: OwnersComponent },
  { path: 'all', component: AllGridComponent },
  { path: 'f', component: FooterComponent },
  { path: 'm', component: MenuComponent },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'truck', loadChildren: () => import('./trucks/trucks.module').then(m => m.TrucksModule) },
  { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
