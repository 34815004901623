import { Pipe, PipeTransform } from '@angular/core';
import { getDownloadURL, ref } from '@angular/fire/storage';
import { AlgoliaFireService } from '../services/algolia.service';

@Pipe({
    name: 'gsToHttpsComparisson'
})

export class GsToHttpsComparisson implements PipeTransform {

    constructor(private algoliaService: AlgoliaFireService) {

    }

    async transform(id: string, arr: any[]) {
        let el: any = null;
        let result = '';
        arr.forEach(async (element: any) => {
            if (element.id == id) {
                el = element;
            }
        });
        const gsReference = ref(this.algoliaService.fire_storage, el?.imageUrl);
        try {
            result = await getDownloadURL(ref(this.algoliaService.fire_storage, gsReference.fullPath));
        } catch (error) {
            console.log(error);
        }
        return result;
    }
}