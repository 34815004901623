
export const environment = {
    firebase: {
      projectId: 'guttrucks',
      appId: '1:1077202539403:web:d7b8baea934a211b81d58e',
      databaseURL: 'https://guttrucks.firebaseio.com',
      storageBucket: 'guttrucks.appspot.com',
      locationId: 'us-central',
      apiKey: 'AIzaSyDKHgcuUyy0cGEdAQyWqYMOzsCR2OKAiY8',
      authDomain: 'guttrucks.firebaseapp.com',
      messagingSenderId: '1077202539403',
      measurementId: 'G-QZ23Y3LVCD',
    },
    algoliaId: 'NU4ZBJ6Q9G',
    algoliaKey: 'c82573cfd9283413aef55d5b03f7079c',
    algoliaApp: 'prod_FOODTRUCKS',
    production: false,
  };
