import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrucksService {
  selectedDayBejavior = new Subject<any>();
  previous_directions: any = [];

  constructor() { }

  selectDay(obj: any) {
    this.selectedDayBejavior.next(obj);
  }

  isInArr(address: string) {
    let result = false;
    if (this.previous_directions.length > 5) {
      this.previous_directions.pop();
    }
    this.previous_directions.forEach((element: any) => {
      if (element.formatted_address == address) {
        result = true;
      }
    });
    return result;
  }

}
