import { Pipe, PipeTransform } from '@angular/core';
import { AlgoliaFireService } from '../services/algolia.service';

@Pipe({
    name: 'getCuisineGeneralTypeAndReturnArray'
})

export class GetCuisineGeneralTypeAndReturnArray implements PipeTransform {

    constructor(private algoliaService: AlgoliaFireService) {

    }

    async transform(items: string[]) {
        let result: any = [];
        result = await this.algoliaService.getCuisineGeneralTypeByIdReturnArray(items);
        // console.log(result);
        
        return result;
    }
}