import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'secondsToDate'
})

export class DatePipe implements PipeTransform {

    constructor() {

    }

    transform(num: any) {
        let result = null;
        result = moment.unix(num).format('DD-MM-YYYY');
        return result;
    }
}