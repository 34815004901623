import { Pipe, PipeTransform } from '@angular/core';
import { getDownloadURL, ref } from '@angular/fire/storage';
import { AlgoliaFireService } from '../services/algolia.service';

@Pipe({
    name: 'getCuisineGeneralType'
})

export class GetCuisineGeneralType implements PipeTransform {

    constructor(private algoliaService: AlgoliaFireService) {

    }

    async transform(items: string[]) {
        let result = '';
        result = await this.algoliaService.getCuisineGeneralTypeById(items);
        return result;
    }
}